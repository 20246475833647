import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useAuthentication } from '../../contexts';
import {
    useFloorUsageTags,
    useFunctionalUnitTags,
    useProjectCreate,
    useProjectSave,
    useProjectTags,
    useStaticData,
} from '../../effects';
import { RoutesEnum } from '../../enums/paths';
import { importProject } from '../../utils';
import { TextButton } from '../Buttons';
import { FileUploader } from '../FileUploader';
import { Spinner } from '../Spinner';
import { ProjectImportArea } from './ProjectImportArea';
import { AxiosError } from 'axios';

interface Props {
    onComplete?: (projects: Project[]) => unknown;
    onClose?: () => unknown;
}

export const ProjectImporter: React.FC<Props> = ({ onComplete, onClose }) => {
    const [projects, setProjects] = useState<Project[]>();
    const [failedImports, setFailedImports] = useState<[File, string][]>();
    const [importing, setImporting] = useState(false);
    const [t] = useTranslation();

    const { data: staticData } = useStaticData();
    const { user } = useAuthentication();
    const { data: projectTags } = useProjectTags();
    const { data: floorUsageTags } = useFloorUsageTags();
    const { data: functionalUnitTags } = useFunctionalUnitTags();

    const { mutateAsync: createProject } = useProjectCreate();
    const { mutateAsync: saveProject } = useProjectSave();

    const handleFileUpload = useCallback(
        async (files) => {
            if (!user) return;
            if (!staticData) return;
            if (!projectTags) return;
            if (!floorUsageTags) return;
            if (!functionalUnitTags) return;

            setImporting(true);
            setProjects([]);

            const projects: Project[] = [];
            const failedImports: [File, string][] = [];

            for (const file of files as File[]) {
                try {
                    let project = await importProject(
                        file,
                        staticData,
                        projectTags,
                        floorUsageTags,
                        functionalUnitTags,
                        user
                    );

                    const id = await createProject({
                        ...project,
                        costIndexDate: project.costIndex.date as string,
                    } as CreateProjectPayload);

                    // Some fields will not be added on the initial creation of the project
                    project = await saveProject({
                        ...project,
                        id,
                        costIndexDate: project.costIndex.date as string,
                    });

                    project.id = id;
                    projects.push(project);
                } catch (error) {
                    if (error instanceof Error) {
                        if (isAxiosError(error)) {
                            console.warn('Error trying to upload imported project', error);
                            failedImports.push([file, 'upload.failed']);
                        } else {
                            console.warn('Error trying to import a project', error);
                            failedImports.push([file, error.message]);
                        }
                    }
                }
            }

            setProjects(projects);
            setFailedImports(failedImports);
            onComplete?.(projects);

            setImporting(false);
        },
        [staticData, projectTags, floorUsageTags, functionalUnitTags, user, createProject, saveProject, onComplete]
    );

    return (
        <div className="project-importer">
            <p>{t('import:text')}</p>

            {importing ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <FileUploader
                    Component={ProjectImportArea as any}
                    Props={{
                        text: t('import:areaText'),
                    }}
                    uploadFile={handleFileUpload}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    multiple={true}
                />
            )}

            {!!projects?.length && (
                <div className="project-importer__result">
                    <p>{t('import:success', { count: projects.length })}</p>
                    <div>
                        {projects.map((project) => (
                            <Row key={project.id}>
                                <Col>- {Object.values(project.name)[0]}</Col>
                                <Col xs="auto">
                                    <a
                                        href={`/#${generatePath(RoutesEnum.PROJECT_PAGE, { projectId: project.id })}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </a>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </div>
            )}

            {!!failedImports?.length && (
                <div className="project-importer__result">
                    <p>{t('import:failed', { count: failedImports.length })}</p>
                    <div>
                        {failedImports.map(([file, error], i) => (
                            <Row key={i}>
                                <Col xs="auto">- {file.name}</Col>
                                <Col>Grund: {t(`import:errors:${error.replaceAll('.', ':')}`)}</Col>
                            </Row>
                        ))}
                    </div>
                </div>
            )}

            {!importing && <TextButton onClick={onClose} name={t('common:close')} />}
        </div>
    );
};

function isAxiosError(err: any): err is AxiosError {
    return err['isAxiosError'];
}
