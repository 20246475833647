import classnames from 'classnames';
import { uniqueId } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

export interface DialogProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    className?: string;
    title?: string;
    body?: React.ReactNode;
    footer?: React.ReactNode;
    closeOnOverlayClick?: boolean;
    onClose?: () => unknown;
    as?: keyof JSX.IntrinsicElements;
}

export const Dialog: React.FC<DialogProps> = ({
    className,
    title,
    body,
    footer,
    closeOnOverlayClick = true,
    onClose,
    as: type = 'div',
    ...props
}) => {
    const handleOverlayClick = React.useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            if (closeOnOverlayClick && e.target === e.currentTarget) onClose?.();
        },
        [onClose, closeOnOverlayClick]
    );

    return (
        <div className="dialog__overlay" onClick={handleOverlayClick}>
            {React.createElement(
                type,
                {
                    ...props,
                    className: classnames('dialog__content', className),
                    'data-testid': 'dialog-content',
                },
                <>
                    {title && <div className="dialog__header">{title}</div>}
                    {body && <div className="dialog__body">{body}</div>}
                    {footer && <div className="dialog__footer">{footer}</div>}
                </>
            )}
        </div>
    );
};

export type OpenDialog = () => Promise<void>;
export type OpenDialogPortal = () => void;

const DIALOG_CONTAINER_ID = `dialog-container`;
function useDialogContainer(): HTMLElement {
    return useMemo(() => {
        let element = document.getElementById(DIALOG_CONTAINER_ID);

        if (!element) {
            element = document.createElement('div');
            element.id = DIALOG_CONTAINER_ID;

            document.body.appendChild(element);
        }

        return element;
    }, []);
}

export function useDialogWrapper(): [HTMLElement, () => HTMLElement] {
    const container = useDialogContainer();
    const dialogId = useMemo(() => uniqueId('dialog-'), []);

    const createElement = useCallback(() => {
        const wrapper = document.createElement('div');
        wrapper.id = dialogId;

        container.appendChild(wrapper);

        return wrapper;
    }, [container, dialogId]);

    const [wrapper] = useState<HTMLElement>(() => createElement());

    const remount = useCallback(() => {
        return container.appendChild(wrapper);
    }, [container, wrapper]);

    return [wrapper, remount];
}

export function useDialog({ className, title, body, footer, closeOnOverlayClick, onClose }: DialogProps): OpenDialog {
    const [wrapper, remount] = useDialogWrapper();

    const props = useMemo(
        () => ({
            className,
            title,
            body,
            footer,
            closeOnOverlayClick,
            onClose,
        }),
        [className, title, body, footer, closeOnOverlayClick, onClose]
    );

    useEffect(() => () => void ReactDOM.unmountComponentAtNode(wrapper), [wrapper, props]);
    useEffect(() => () => wrapper.remove(), [wrapper]);

    return useCallback(() => {
        return new Promise<void>((resolve): void => {
            const handleClose = async () => {
                // Unmount
                ReactDOM.unmountComponentAtNode(wrapper);

                resolve();

                props.onClose?.();
            };

            ReactDOM.render(<Dialog {...props} onClose={handleClose} />, remount());
        });
    }, [wrapper, props, remount]);
}

export function useDialogPortal({
    className,
    title,
    body,
    footer,
    closeOnOverlayClick,
    onClose,
}: DialogProps): [OpenDialogPortal, React.ReactElement | null, () => void] {
    const [container, remount] = useDialogWrapper();
    const [state, setState] = useState<{ isOpen: boolean }>({
        isOpen: false,
    });

    const props = useMemo(
        () => ({
            className,
            title,
            body,
            footer,
            closeOnOverlayClick,
            onClose,
        }),
        [className, title, body, footer, closeOnOverlayClick, onClose]
    );

    const handleCancel = useCallback(async () => onClose?.(), [onClose]);

    const handleClose = useCallback(async () => handleCancel(), [handleCancel]);

    const openDialog = useCallback(() => {
        remount();
        setState({ isOpen: true });
    }, [remount]);
    const closeDialog = useCallback(() => setState({ isOpen: false }), []);

    const portal = useMemo(() => {
        if (!state.isOpen) return null;

        return ReactDOM.createPortal(<Dialog {...props} onClose={handleClose} />, container);
    }, [state, props, container, handleClose]);

    return [openDialog, portal, closeDialog];
}
