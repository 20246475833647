import { groupBy, identity } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import XLSX, { CellObject } from 'xlsx';
import type { UserInfo } from '../types';
import { FIELD_IDS } from './excel-export';

function getSheetRows(sheet: XLSX.Sheet): XLSX.CellObject[][] {
    const [start, end] = sheet['!ref']?.split(':') ?? [];

    const cols = createCols(start[0], end[0]);

    return getRows(sheet, cols, 2, parseInt(end.substring(1)));
}

function createCols(startChar: string, endChar: string) {
    const cols = [];

    const endCharCode = endChar.charCodeAt(0);
    for (let i = startChar.charCodeAt(0); i <= endCharCode; i++) {
        cols.push(String.fromCharCode(i));
    }

    return cols;
}

function getRows(sheet: XLSX.Sheet, cols: string[], minRow: number, maxRow: number) {
    const rows = [];

    for (let i = minRow; i <= maxRow; i++) {
        const row = cols.map((col) => sheet[`${col}${i}`]);
        rows.push(row);
    }

    return rows;
}

export async function importProject(
    file: File,
    staticData: StaticData,
    projectTags: ProjectTag[],
    floorUsageTags: FloorUsageTag[],
    functionalUnitTag: FloorUsageTag[],
    userinfo: UserInfo
): Promise<Project> {
    let book: XLSX.WorkBook;

    try {
        const buffer = await file.arrayBuffer();
        book = XLSX.read(buffer);
    } catch (e) {
        throw new Error('import.invalid.file');
    }

    if (!book.Workbook) throw new Error('import.invalid.file');
    if (!book.Workbook.Sheets) throw new Error('import.invalid.file');

    const datasheet = getSheetRows(book.Sheets['Datenblatt']);
    const languageCode = getOrError(() => getString(datasheet[0][2], true), 'import.invalid.lang');
    const name = getOrError(() => getString(datasheet[2][3], true), 'import.invalid.name');
    const creator = userinfo.username;
    const owner = getString(datasheet[5][3]);
    const costIndexDate = getOrError(() => getString(datasheet[3][3], true), 'import.invalid.costIndexDate');

    const greaterRegion = getOrError(
        () => getGreaterRegion(staticData.greaterRegions, datasheet, languageCode),
        'import.invalid.greaterRegion'
    );
    const costIndex = getCostIndex(staticData.costIndices, datasheet, greaterRegion);
    const buildingClassPartners = getOrError(
        () =>
            getBuildingClass(
                'Werkkategorie',
                staticData.buildingClassification.partners,
                datasheet,
                languageCode,
                true
            ),
        'import.invalid.buildingClass.partners'
    );
    const buildingClassCrb = getBuildingClass(
        'Objektart CRB OAG',
        staticData.buildingClassification.crb,
        datasheet,
        languageCode
    );
    const projectAddress = getAddress(staticData, datasheet);

    const constructionTime = getConstructionTime(datasheet);
    const parameters = getParameters(datasheet, staticData, languageCode);
    const projectSpecificReadings = getProjectSpecificReadings(datasheet);
    const descriptions = getDescriptions(datasheet, staticData, languageCode);
    const source = getSource(datasheet);
    const alternativeSource = getDatasheetValue(datasheet, FIELD_IDS.DATA_SOURCE.ALTERNATIVE_SOURCES, getString);

    const tags = getTags(getSheetRows(book.Sheets['Datenblatt_Tags']), projectTags, languageCode);
    const usages = getUsages(
        getSheetRows(book.Sheets['Datenblatt_Nutzungen']),
        staticData,
        floorUsageTags,
        languageCode
    );

    const members = getMembers(getSheetRows(book.Sheets['Datenblatt_Beteiligte']), staticData, languageCode);
    const functionalUnits = getFunctionalUnits(
        getSheetRows(book.Sheets['Datenblatt_FU']),
        functionalUnitTag,
        languageCode
    );
    const linkedProjects = getLinkedProjects(getSheetRows(book.Sheets['Datenblatt_Verlinkte_Projekte']));
    const buildings = getBuildings(getSheetRows(book.Sheets['Gebäude']), languageCode);
    const measurements = getMeasurements(getSheetRows(book.Sheets['Flächen_und_Volumen']), staticData);
    const costs = getCosts(book, staticData, languageCode);
    const energies = getEnergy(getSheetRows(book.Sheets['Energie']), staticData.energyUnits);
    const energyParameters = getEnergyParameter(
        getSheetRows(book.Sheets['Energie_Parameter']),
        staticData.energyParameterTypes
    );
    const treeValues = getEnergyTree(getSheetRows(book.Sheets['Energie_Baum']), staticData.energyTrees);

    const project: Project = {
        ...({} as Project),
        name: { [languageCode]: name },
        creator: creator,
        owner,
        greaterRegion: greaterRegion,
        costIndexDate: costIndexDate,
        costIndex,
        buildingClassPartners,
        buildingClassCrb,
        projectAddress,
        constructionTime,
        parameters,
        projectSpecificReadings,
        descriptions,
        source,
        alternativeSource,

        members,
        functionalUnits,
        measurements,
        linkedProjects,
        costs,
        energyInfo: {
            energies,
            parameters: energyParameters,
            treeValues,
        },
        buildings,

        usages,
        tags,

        totalCosts: [],
    };

    return project;
}

function getGreaterRegion(greaterRegions: StaticRegion[], datasheetRows: CellObject[][], languageCode: string) {
    return getStaticDataEntry(
        datasheetRows[22],
        greaterRegions,
        getNumber,
        ({ id }, greaterRegionId) => id === greaterRegionId,
        ({ name }, greaterRegionName) => name[languageCode] === greaterRegionName,
        true
    );
}

function getCostIndex(
    costIndices: StaticCostIndex[],
    datasheetRows: CellObject[][],
    region: ObjectReference<StaticRegion>
) {
    return getStaticDataEntry(
        datasheetRows[3],
        costIndices,
        getNumber,
        ({ id }, greaterRegionId) => id === greaterRegionId,
        (ci, costIndexDate) => ci.date === costIndexDate && ci.greaterRegion.id === region.id,
        true
    );
}

function getBuildingClass(
    columnName: string,
    buildingClassifications: StaticBuildingClassification[],
    datasheetRows: CellObject[][],
    languageCode: string,
    required: true
): StaticBuildingClassification;
function getBuildingClass(
    columnName: string,
    buildingClassifications: StaticBuildingClassification[],
    datasheetRows: CellObject[][],
    languageCode: string,
    required?: false | undefined
): StaticBuildingClassification | undefined;
function getBuildingClass(
    columnName: string,
    buildingClassifications: StaticBuildingClassification[],
    datasheetRows: CellObject[][],
    languageCode: string,
    required?: boolean
) {
    const rowIndex = datasheetRows.findIndex((row) => getString(row[1]) === columnName);

    if (rowIndex < 0) return undefined;

    return getStaticDataEntry(
        datasheetRows[rowIndex],
        buildingClassifications,
        getNumber,
        ({ id }, buildingClassId) => id === buildingClassId,
        ({ classificationCode, description }, text) => {
            if (text === classificationCode) return true;
            if (text === description[languageCode]) return true;
            if (text === `${classificationCode}: ${description[languageCode]}`) return true;

            return false;
        },
        required as true
    );
}

function getAddress(staticData: StaticData, datasheetRows: CellObject[][]): ProjectAddress {
    const location = getLocation(staticData.locations, datasheetRows);

    return {
        location,
        city: getDatasheetValue(datasheetRows, FIELD_IDS.LOCATION.CITY, getString),
        streetName: getDatasheetValue(datasheetRows, FIELD_IDS.LOCATION.STREET, getString),
        streetNumber: getDatasheetValue(datasheetRows, FIELD_IDS.LOCATION.STREET_NUMBER, getString),
        additional: getDatasheetValue(datasheetRows, FIELD_IDS.LOCATION.ADDRESS_SUPPLEMENT, getString),
        bfsNumber: getDatasheetValue(datasheetRows, FIELD_IDS.LOCATION.COORDINATES.BFS, getNumber),
        egidNumber: getDatasheetValue(datasheetRows, FIELD_IDS.LOCATION.COORDINATES.EGID, getString),
        latitude: getDatasheetValue(datasheetRows, FIELD_IDS.LOCATION.COORDINATES.LAT, getNumber),
        longitude: getDatasheetValue(datasheetRows, FIELD_IDS.LOCATION.COORDINATES.LON, getNumber),
        plusCodes: getDatasheetValue(datasheetRows, FIELD_IDS.LOCATION.COORDINATES.GOOGLE_PLUS_CODES, getString),
    };
}

function getLocation(locations: StaticLocation[], datasheetRows: CellObject[][]): ProjectLocation {
    return getOrError(
        () =>
            getStaticDataEntry(
                datasheetRows[24],
                locations,
                getNumber,
                ({ zipCode }, postalCode) => zipCode === postalCode,
                ({ zipCode, municipality }, value) =>
                    `${zipCode}` === value || municipality === value || `${zipCode} ${municipality}` === value,
                true
            ),
        'invalid.location'
    );
}

function getStaticDataEntry<D, ID>(
    datasheetRow: XLSX.CellObject[],
    data: D[],
    identity: (cell: XLSX.CellObject) => ID | undefined,
    matchesByIdentity: (d: D, identity: ID) => boolean,
    matchesByValue: (d: D, value: string) => boolean,
    required: true,
    def?: [number | undefined, number | undefined]
): D;
function getStaticDataEntry<D, ID>(
    datasheetRow: XLSX.CellObject[],
    data: D[],
    identity: (cell: XLSX.CellObject) => ID | undefined,
    matchesByIdentity: (d: D, identity: ID) => boolean,
    matchesByValue: (d: D, value: string) => boolean,
    required?: false | undefined,
    def?: [number | undefined, number | undefined]
): D | undefined;
function getStaticDataEntry<D, ID>(
    datasheetRow: XLSX.CellObject[],
    data: D[],
    identity: (cell: XLSX.CellObject) => ID | undefined,
    matchesByIdentity: (d: D, identity: ID) => boolean,
    matchesByValue: (d: D, value: string) => boolean,
    required?: boolean,
    def?: [number | undefined, number | undefined]
): D | undefined {
    let d: D | undefined;

    const id = identity(datasheetRow[def?.[0] ?? 2]);
    if (id) {
        d = data.find((d) => matchesByIdentity(d, id));
    }

    if (!d) {
        const value = getString(datasheetRow[def?.[1] ?? 3], required);
        d = data.find((d) => matchesByValue(d, value));
    }

    if (required && !d) throw new Error('Expected to find value, but found nothing');

    return d;
}

function getDatasheetValue<T>(
    datasheet: XLSX.CellObject[][],
    id: number,
    getValue: (cell: XLSX.CellObject | undefined, required?: boolean) => T
) {
    const index = datasheet.findIndex((row) => getNumber(row[0]) === id);

    if (index < 0) return undefined;

    return getValue(datasheet[index][3]);
}

function getConstructionTime(datasheet: XLSX.CellObject[][]): ConstructionTime {
    return {
        moveIn: getDatasheetValue(datasheet, FIELD_IDS.CONSTRUCTION_END, getString) ?? '',
        constructionStart: getDatasheetValue(datasheet, FIELD_IDS.CONSTRUCTION_START, getString) ?? '',
        planningStart: getDatasheetValue(datasheet, FIELD_IDS.PLANNING_START, getString) ?? '',
        competition: getDatasheetValue(datasheet, FIELD_IDS.COMPETITION_START, getString) ?? '',
        duration: getDatasheetValue(datasheet, FIELD_IDS.CONSTRUCTION_DURATION, getNumber) ?? 0,
    };
}

function getParameters(
    datasheet: XLSX.CellObject[][],
    staticData: StaticData,
    languageCode: string
): ProjectParameter[] {
    return [
        getParameter(datasheet[12], staticData, languageCode),
        getParameter(datasheet[13], staticData, languageCode),
        getParameter(datasheet[14], staticData, languageCode),
        getParameter(datasheet[15], staticData, languageCode),
        getParameter(datasheet[16], staticData, languageCode),
        getParameter(datasheet[17], staticData, languageCode),
        getParameter(datasheet[18], staticData, languageCode),
    ].filter((value): value is ProjectParameter => !!value);
}

function getParameter(
    datasheetRow: XLSX.CellObject[],
    staticData: StaticData,
    languageCode: string
): ProjectParameter | undefined {
    const parameterKeyId = getNumber(datasheetRow[0]);
    if (!parameterKeyId) return undefined;

    const parameterKey = (
        {
            20: 'PROJECT_TYPE',
            21: 'CLIENT',
            22: 'FACADE',
            23: 'PROJECT_PHASE',
            24: 'ROOF_SHAPE',
            25: 'CONSTRUCTION_TYPE',
            26: 'CONSTRUCTION',
        } as Record<number, string>
    )[parameterKeyId];

    const parameter = staticData.parameters.find(({ key }) => key === parameterKey);
    if (!parameter) return undefined;

    const parameterValue = getStaticDataEntry(
        datasheetRow,
        parameter.values,
        getNumber,
        ({ id }, identity) => id === identity,
        ({ name }, value) => name[languageCode] === value
    );

    if (!parameterValue) return undefined;

    return {
        typeId: parameter.id,
        valueId: parameterValue.id,
    };
}

function getProjectSpecificReadings(datasheet: XLSX.CellObject[][]): ProjectSpecificReadings {
    return {
        parkingSpaces: getDatasheetValue(datasheet, FIELD_IDS.MOBILITY.PARKING_SPACES, getNumber),
        cycleParkingSpaces: getDatasheetValue(datasheet, FIELD_IDS.MOBILITY.CYCLE_PARKING_SPACES, getNumber),
        vehicleChargingStations: getDatasheetValue(datasheet, FIELD_IDS.MOBILITY.VEHICLE_CHARGING_STATIONS, getNumber),
        plotRatio: getDatasheetValue(datasheet, FIELD_IDS.MOBILITY.PLOT_RATIO, getNumber),
        zoneAllocation: getDatasheetValue(datasheet, FIELD_IDS.MOBILITY.ZONE_ALLOCATION, getString),
        siteMasterplan: getDatasheetValue(datasheet, FIELD_IDS.MOBILITY.SITE_MASTERPLAN, getBoolean),
        plotRatioBonus: getDatasheetValue(datasheet, FIELD_IDS.MOBILITY.PLOT_RATIO_BONUS, getBoolean),
    };
}

function getDescriptions(
    datasheet: XLSX.CellObject[][],
    staticData: StaticData,
    languageCode: string
): ProjectDescription[] {
    const descriptions = datasheet.slice(42, 51);

    return descriptions
        .map<Partial<ProjectDescription>>((descriptionRow) => {
            const type = staticData.descriptionTypes.find(({ id }) => id === getNumber(descriptionRow[0], true));

            return {
                type,
                text: { [languageCode]: getString(descriptionRow[3]) },
            };
        })
        .filter(
            (description): description is ProjectDescription => !!description.type && !!description.text?.[languageCode]
        );
}

function getSource(datasheet: XLSX.CellObject[][]): Source {
    return {
        source: getDatasheetValue(datasheet, FIELD_IDS.DATA_SOURCE.SOURCE, getString) ?? '',
        catalogue: getDatasheetValue(datasheet, FIELD_IDS.DATA_SOURCE.CATALOGUE, getString) ?? '',
        internalNumber: getDatasheetValue(datasheet, FIELD_IDS.DATA_SOURCE.INTERNAL_NUMBER, getString) ?? '',
        dataSource: getDatasheetValue(datasheet, FIELD_IDS.DATA_SOURCE.DATA_SOURCE, getString) ?? '',
        publicationDate: getDatasheetValue(datasheet, FIELD_IDS.DATA_SOURCE.PUBLICATION_DATE, getString) ?? '',
    };
}

function getTags(datasheet: XLSX.CellObject[][], tags: ProjectTag[], languageCode: string) {
    const ts: ProjectTag[] = [];

    for (const row of datasheet) {
        const id = getNumber(row[0]);
        const name = getString(row[1]);
        const tag = getTag(
            tags,
            id,
            name,
            languageCode,
            (name) =>
                ({
                    name: { [languageCode]: name },
                    isPublic: false,
                } as ProjectTag)
        );

        if (tag) ts.push(tag);
    }

    return ts;
}

function getUsages(
    datasheet: XLSX.CellObject[][],
    staticData: StaticData,
    tags: FloorUsageTag[],
    languageCode: string
) {
    const fus: FloorUsage[] = [];

    for (const row of datasheet) {
        const id = getNumber(row[0]);
        const name = getString(row[1]);
        const tag = getTag(
            tags,
            id,
            name,
            languageCode,
            (name) =>
                ({
                    name: { [languageCode]: name },
                } as ProjectTag)
        );

        if (!tag) continue;

        const regId = getNumber(row[2]);
        const regCode = getString(row[3]);
        const regName = getString(row[4]);

        const regulation = staticData.regulations.SIA416.find((reg) => {
            if (regId && reg.id === regId) return true;
            if (regCode && reg.code === regCode) return true;
            if (regName && reg.description[languageCode] === regName) return true;

            return false;
        });

        if (!regulation) continue;

        const area = getNumber(row[5]);

        if (!area) continue;

        fus.push({ regulation, tag, area });
    }

    return fus;
}

function getTag<T extends Tag>(
    tags: T[],
    id: number | undefined,
    name: string | undefined,
    languageCode: string,
    orElse: (name: string) => T | undefined
) {
    let tag: T | undefined;

    if (id) {
        tag = tags.find((tag) => tag.id === id);
    }

    if (!tag && name) {
        tag = tags.find((tag) => tag.name[languageCode] === name);
    }

    if (!tag && name) tag = orElse(name);

    return tag;
}

function getMembers(datasheet: XLSX.CellObject[][], staticData: StaticData, languageCode: string): ProjectMember[] {
    return datasheet.map((row) => {
        const name = getString(row[0]);
        const role = getStaticDataEntry(
            row,
            staticData.memberRoles,
            getNumber,
            ({ id }, identity) => id === identity,
            ({ name }, value) => name[languageCode] === value,
            true,
            [1, 2]
        );

        return { role, name };
    });
}

function getFunctionalUnits(datasheet: XLSX.CellObject[][], tags: Tag[], languageCode: string): FunctionalUnitModel[] {
    return datasheet
        .map((row) => {
            const id = getNumber(row[0]);
            const name = getString(row[1]);

            const tag = getTag(
                tags,
                id,
                name,
                languageCode,
                (name) => ({ name: { [languageCode]: name } } as FunctionalUnitTag)
            );

            return {
                tag,
                amount: getNumber(row[row.length - 1], true),
            };
        })
        .filter((unit): unit is FunctionalUnitModel => !!unit.tag);
}

function getLinkedProjects(datasheet: XLSX.CellObject[][]): number[] {
    return datasheet.map((row) => getNumber(row[0], true)); // TODO: Validate if project with given id exists
}

function getMeasurements(datasheet: XLSX.CellObject[][], staticData: StaticData): ProjectMeasurement[] {
    return datasheet
        .map((row): [number, ...XLSX.CellObject[]] => [getNumber(row[5]), ...row])
        .filter(([value]) => !!value)
        .map(([value, ...row]) => {
            const regulation = getStaticDataEntry(
                row,
                [...staticData.regulations.Mengen, ...staticData.regulations.SIA116, ...staticData.regulations.SIA416],
                getString,
                ({ code }, identity) => identity === code,
                () => false,
                true,
                [1, 3]
            );

            return { regulation, value };
        });
}

function getBuildings(datasheet: XLSX.CellObject[][], languageCode: string): Building[] {
    const buildings: Building[] = [];
    let building: Building | undefined;

    for (const row of datasheet) {
        const type = getString(row[2]);

        if (!building && type !== 'BUILDING') throw new Error('invalid.building.format');

        if (type === 'BUILDING') {
            const name = getString(row[3], true);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const id = getString(row[0]) ?? uuidv4();
            const referenceHeight = getNumber(row[4]);

            building = {
                id,
                name: { [languageCode]: name },
                referenceHeight,
                floors: [],
            };

            buildings.push(building);
        } else if (type === 'FLOOR') {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const id = getString(row[0]) ?? uuidv4();
            const floorNumber = getNumber(row[1]);
            const height = getNumber(row[5]);
            const area = getNumber(row[6]);

            const floorType: FloorType =
                floorNumber === 0 ? 'GROUND' : floorNumber > 0 ? 'ABOVE_GROUND' : 'UNDER_GROUND';

            building?.floors.push({
                id,
                floorType,
                floorNumber: Math.abs(floorNumber),
                height,
                area,
                floorFunctions: [],
            });
        }
    }

    return buildings;
}

function getEnergy(datasheet: XLSX.CellObject[][], energies: EnergyUnit[]): ProjectBuildingEnergy[] {
    return datasheet
        .map((row): [number, ...XLSX.CellObject[]] => [getNumber(row[2]), ...row])
        .filter(([value]) => !!value)
        .map(([value, ...row]) => {
            const energyUnit = getStaticDataEntry(
                row,
                energies,
                getNumber,
                ({ id }, identity) => id === identity,
                () => false,
                true,
                [0, 1]
            );

            return { energyUnit, value };
        });
}

function getEnergyParameter(
    datasheet: XLSX.CellObject[][],
    energyParameters: EnergyParameterType[]
): ProjectEnergyParameter[] {
    return datasheet
        .map((row): [string | number | boolean | Date | undefined, ...XLSX.CellObject[]] => [getValue(row[3]), ...row])
        .filter(([value]) => !!value)
        .map(([value, ...row]) => {
            const type = getStaticDataEntry(
                row,
                energyParameters,
                getNumber,
                ({ id }, identity) => id === identity,
                (d, value) => Object.values(d.name).some((name) => name === value),
                true,
                [0, 1]
            );

            switch (type.type) {
                case 'ENUMERATED': {
                    const value = getStaticDataEntry(
                        row,
                        type.values,
                        getNumber,
                        ({ id }, identity) => id === identity,
                        (d, value) => Object.values(d.value).some((name) => name === value),
                        true,
                        [2, 3]
                    );

                    return {
                        id: -1,
                        type,
                        valueType: 'ENUMERATED',
                        value,
                    } as ProjectEnergyParameterEnumerated;
                }
                case 'NUMERIC':
                    return {
                        id: -1,
                        type,
                        valueType: 'NUMERIC',
                        num: validateType(value, 'number'),
                    } as ProjectEnergyParameterNumeric;
                case 'TEXT':
                    return {
                        id: -1,
                        type,
                        valueType: 'TEXT',
                        text: validateType(value, 'string'),
                    } as ProjectEnergyParameterText;
            }

            return undefined;
        })
        .filter((param): param is ProjectEnergyParameter => !!param);
}

function getEnergyTree(datasheet: XLSX.CellObject[][], trees: EnergyTree[]): Record<string, ProjectEnergyTreeValue[]> {
    trees = flattenBy(trees, ({ children }) => children);

    const rows = datasheet
        .map((row): [number, number, ...XLSX.CellObject[]] => [getNumber(row[2]), getNumber(row[3]), ...row])
        .filter(([watt, co2]) => !!watt || co2)
        .map<[EnergyTree, ProjectEnergyTreeValue]>(([watt = 0, co2 = 0, ...row]) => {
            const tree = getStaticDataEntry(
                row,
                trees,
                getNumber,
                ({ id }, identity) => id === identity,
                () => false,
                true,
                [0, 1]
            );

            return [
                tree,
                {
                    tree,
                    watt,
                    co2,
                },
            ];
        });

    return groupBy(
        rows.map(([tree, value]) => ({ ...value, tree })),
        ({ tree }) => tree.type
    );
}

function getCosts(book: XLSX.WorkBook, staticData: StaticData, languageCode: string): ProjectCost[] {
    return [
        ...getBKPCosts(book.Sheets['Kennwerte BKP'], staticData.regulations.BKP, languageCode),
        ...getEBKPCosts(book.Sheets['Kennwerte eBKP-H'], staticData.regulations['eBKP-H'], languageCode),
        ...getEBKPCosts(book.Sheets['Kennwerte eBKP-T(A)'], staticData.regulations['eBKP-T(A)'], languageCode),
        ...getEBKPCosts(book.Sheets['Kennwerte eBKP-T(B)'], staticData.regulations['eBKP-T(B)'], languageCode),
        ...getEBKPCosts(book.Sheets['Kennwerte eBKP-H2020(A)'], staticData.regulations['eBKP-H2020(A)'], languageCode),
        ...getEBKPCosts(book.Sheets['Kennwerte eBKP-H2020(B)'], staticData.regulations['eBKP-H2020(B)'], languageCode),
    ];
}

function getBKPCosts(sheet: XLSX.WorkSheet, regulations: StaticRegulation[], languageCode: string): ProjectCost[] {
    const rows = getSheetRows(sheet);

    return rows
        .map(([code, , value, description]): [string, number | undefined, string | undefined] => [
            getString(code, true),
            getNumber(value),
            getString(description),
        ])
        .filter(([, value, description]) => !!value || !!description)
        .map(([code, value = 0, description = '']): [StaticRegulation | undefined, number, string] => {
            const regulation = regulations.find((reg) => reg.code === code);

            return [regulation, value, description];
        })
        .filter(([regulation]) => !!regulation)
        .map(([regulation, value, description]) => ({
            regulation: regulation as StaticRegulation,
            description: { [languageCode]: description },
            value,
        }));
}

function getEBKPCosts(sheet: XLSX.WorkSheet, regulations: StaticRegulation[], languageCode: string): ProjectCost[] {
    const rows = getSheetRows(sheet);

    return rows
        .map(
            ([code, , , referenceQuantity, , value, description]): [
                string,
                number | undefined,
                number | undefined,
                string | undefined
            ] => [getString(code, true), getNumber(referenceQuantity), getNumber(value), getString(description)]
        )
        .filter(([, referenceQuantity, value, description]) => !!referenceQuantity || !!value || !!description)
        .map(
            ([code, referenceQuantity = 0, value = 0, description = '']): [
                StaticRegulation | undefined,
                number,
                number,
                string
            ] => {
                const regulation = regulations.find((reg) => reg.code === code);

                return [regulation, referenceQuantity, value, description];
            }
        )
        .filter(([regulation]) => !!regulation)
        .map(([regulation, referenceQuantity, value, description]) => ({
            regulation: regulation as StaticRegulation,
            description: { [languageCode]: description },
            value,
            referenceQuantity,
        }));
}

function flattenBy<T, R = T>(items: T[], next: (item: T) => T[], map: (item: T) => R = identity): R[] {
    return items.flatMap((item) => {
        const nextItems = next(item);

        if (nextItems.length === 0) return [map(item)];

        return [map(item), ...flattenBy(next(item), next, map)];
    });
}

function getValue(cell: XLSX.CellObject | undefined): string | number | boolean | Date | undefined {
    return cell?.v;
}

function getNumber(cell: XLSX.CellObject | undefined, required?: boolean): number {
    let value = getValue(cell);

    value = typeof value === 'number' ? value : typeof value === 'string' ? parseFloat(value) : value;

    return validateType(value, 'number', required);
}

function getOrError<D>(func: () => D, message: string) {
    try {
        return func();
    } catch (error) {
        throw new Error(message);
    }
}

function getString(cell: XLSX.CellObject | undefined, required?: boolean): string {
    return validateType(getValue(cell), 'string', required);
}

function getBoolean(cell: XLSX.CellObject | undefined, required?: boolean): boolean {
    const val = getValue(cell);

    if (typeof val === 'boolean') return val;
    if (typeof val === 'string') return val === 'Ja';

    return validateType(undefined, 'boolean', required);
}

/*
function getDate(cell: XLSX.CellObject | undefined, required?: boolean): Date {
    return validateType(getValue(cell), 'Date', required);
}
*/

function validateType<V>(val: V, expectedType: 'string', required?: boolean): string;
function validateType<V>(val: V, expectedType: 'number', required?: boolean): number;
function validateType<V>(val: V, expectedType: 'boolean', required?: boolean): boolean;
function validateType<V>(val: V, expectedType: 'Date', required?: boolean): Date;
function validateType<V>(val: V, expectedType: string, required?: boolean): unknown {
    if (!required && (val === null || val === undefined)) return val;
    if (required && (typeof val === 'undefined' || (typeof val === 'object' && !val)))
        throw new Error('Value is nullish');
    if (expectedType === 'Date') return new Date(validateType(val, 'string', required));
    if (val && typeof val !== expectedType) throw new Error('Validation of type failed');

    return val;
}
