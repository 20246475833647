export default {
    name: 'Objekt-$t(common:editor)',
    'object-calculator': 'Objekt-Kalkulator',
    language: 'Dokumenationssprache',
    'leave-editor': 'Sind Sie sicher, dass Sie gehen möchten, ohne die Änderungen zu speichern?',
    'open-ebkph-wizard': 'Umschlüsselung nach $t(editor:regulation:eBKP-H)',
    'close-wizard': 'Assistent schliessen',
    'wizard-underway': {
        title: 'Achtung!',
        message:
            'Achtung: Sie haben bereits mit der Konvertierung dieses Projekts im Assistent begonnen. Jede Änderung der Referenzwerte führt dazu, dass der Konvertierungsassistent zurückgesetzt wird.',
        'save-anyway': 'Trotzdem speichern',
        cancel: 'Abbrechen',
    },
    'arithmetically-incorrect-error-message':
        'Die BKP-Beträge der Unterkategorien müssen in der Summe den Betrag der Kategorie ergeben. Bitte korrigieren Sie Ihre Eingaben, bevor Sie die Umschlüsselung vornehmen.',
    'input-file-import': {
        'incorrect-input-file-type': {
            dialog: {
                title: 'Falscher Datei Typ',
                description:
                    'Der Typ der Datei scheint nicht korrekt zu sein. Es werden nur DXF oder IFC Dateien funktionieren.',
                confirm: 'Datei dennoch öffnen',
                cancel: 'Andere Datei wählen',
            },
        },
    },
    regulation: {
        BKP: 'BKP',
        'eBKP-H': 'eBKP-H',
        'eBKP-T(A)': 'eBKP-T(A)',
        'eBKP-T(B)': 'eBKP-T(B)',
        'eBKP-H2020(A)': 'eBKP-H (A) 2020',
        'eBKP-H2020(B)': 'eBKP-H (B) 2020',
    },
    pages: {
        data: 'Datenblatt',
        measurements: 'Flächen & Volumen',
        buildings: 'Gebäude',
        costs: 'Kennwerte',
        energy: 'Energie',
        media: 'Bilder & Pläne',
        wizard: 'Wizard',
    },
    project: {
        parameters: {
            id: 'ID',
            projectId: 'PrID',
            name: 'Name',
            creator: 'Benutzer-ID',
            owner: 'Firma',
            greaterRegion: { id: 'Grossregion BFS' },
            type: 'Objektart',
            status: 'Status',
            alternativeSource: 'Alternative Quelle',
            linkedProjects: 'Verlinkte Projekte',
            adminStatus: 'Publizierungstatus',
            buildingClassCrb: {
                id: 'Objektart CRB OAG',
            },
            buildingClassPartners: {
                id: 'Werkkategorie',
            },
            source: {
                source: 'Vermerk Projektnachweis',
                catalogue: 'Katalog/Register/Index',
                internalNumber: 'Interne Nr/Index-Nr.',
                publicationDate: 'Veröffentlichungsdatum',
                dataSource: 'Datenquelle',
            },
            descriptions: {
                generalDescription: 'Generelle Beschreibung',
                usageConcept: 'Nutzungskonzept',
                construction: 'Konstruktion',
                buildingTechnology: 'Gebäudetechnik',
                materials: 'Materialien',
                design: 'Design',
                energy: 'Energie',
                userComfort: 'Benutzerkomfort',
                organisation: 'Projektorganisation',
            },
            parameters: {
                constructionMethod: 'Baumehode',
                constructionMode: 'Baumodus',
                constructionMeasure: 'Baumassnahmen',
                constructionOwner: 'Bauherr',
                projectPhase: 'Projektphase',
            },
            participants: {
                name: 'Name',
                role: 'Rolle',
                'delete-participant': 'Löschen',
                'add-participant': 'Beteiligte/-r hinzufügen',
            },
            projectTags: {
                title: 'Tags',
                projectTagName: 'Tag Name',
                deleteProjectTag: 'Löschen',
                addProjectTag: 'Tag hinzufügen',
                isNew: 'Neuer Tag erstellen',
                isPublic: 'Öffentlich',
                isPrivate: 'Privat',
                showTags: 'Tags anzeigen',
                tagEditor: {
                    title: 'Projekt Tags',
                },
                replaceDelete: {
                    title: 'Tag ersetzen',
                    description:
                        'Der Tag wird mit dem ausgewählten Tag ersetzt und anschliessen gelöscht. \nWollen Sie den Tag wirklich ersetzen?',
                    cancel: 'Abbrechen',
                    confirm: 'Ersetzen',
                },
                confirmDelete: {
                    title: 'Tag löschen',
                    description:
                        'Der Tag wird nach dem Löschen nicht mehr zur Verfügung. \nWollen Sie den Tag wirklich löschen?',
                    cancel: 'Abbrechen',
                    confirm: 'Löschen',
                },
            },
            floorUsages: {
                title: 'Nutzungen',
                tagName: 'Nutzung',
                regulation: 'Regulierung',
                area: 'Fläche',
                deleteFloorUsage: 'Löschen',
                addFloorUsage: 'Nutzung hinzufügen',
                isNew: 'Neue Nutzung erstellen',
                isPublic: 'Öffentlich',
                isPrivate: 'Privat',
                tags: {
                    showTags: 'Tags anzeigen',
                    tagEditor: {
                        title: 'Nutzungen Tags',
                    },
                    replaceDelete: {
                        title: 'Tag ersetzen',
                        description:
                            'Der Tag wird mit dem ausgewählten Tag ersetzt und anschliessen gelöscht. \nWollen Sie den Tag wirklich ersetzen?',
                        cancel: 'Abbrechen',
                        confirm: 'Ersetzen',
                    },
                    confirmDelete: {
                        title: 'Tag löschen',
                        description:
                            'Der Tag wird nach dem Löschen nicht mehr zur Verfügung. \nWollen Sie den Tag wirklich löschen?',
                        cancel: 'Abbrechen',
                        confirm: 'Löschen',
                    },
                },
            },
            functionalUnits: {
                title: 'Funktionale Einheiten',
                amount: 'Anzahl',
                functionalUnit: 'Funktionale Einheit',
                deleteFunctionalUnit: 'Löschen',
                addFunctionalUnit: 'Funktionale Einheit hinzufügen',
                tags: {
                    showTags: 'Tags anzeigen',
                    tagEditor: {
                        title: 'Funktionale Einheit Tags',
                    },
                    replaceDelete: {
                        title: 'Tag ersetzen',
                        description:
                            'Der Tag wird mit dem ausgewählten Tag ersetzt und anschliessen gelöscht. \nWollen Sie den Tag wirklich ersetzen?',
                        cancel: 'Abbrechen',
                        confirm: 'Ersetzen',
                    },
                    confirmDelete: {
                        title: 'Tag löschen',
                        description:
                            'Der Tag wird nach dem Löschen nicht mehr zur Verfügung. \nWollen Sie den Tag wirklich löschen?',
                        cancel: 'Abbrechen',
                        confirm: 'Löschen',
                    },
                },
            },
            projectSpecificReadings: {
                statutoryPlanningFramework: 'Baurechtliche Rahmenbedingungen',
                plotRatio: 'Ausnützungsziffer',
                zoneAllocation: 'Zonenzugehörigkeit',
                siteMasterplan: 'Gestaltungsplan',
                plotRatioBonus: 'Bonus Ausnützung',
                mobility: 'Mobilität',
                parkingSpaces: 'Parkplätze',
                cycleParkingSpaces: 'Veloabstellplätze',
                vehicleChargingStations: 'Elektroladestationen',
            },
            projectAddress: {
                city: 'Ort',
                streetName: 'Strasse',
                streetNumber: 'Nr.',
                bfsNumber: 'BFS-Nummer',
                latitude: 'Geographische Breite',
                longitude: 'Geographische Länge',
                plusCodes: 'Google Plus Codes',
                egidNumber: 'Eidg. Gebäudeidentifikator (EGID)',
                additional: 'Zusatz',
                location: {
                    municipality: 'Gemeinde',
                    country: 'Land',
                    position: 'Lage',
                    countries: {
                        switzerland: 'Schweiz',
                        liechtenstein: 'Liechtenstein',
                    },
                    canton: {
                        cantonName: 'Kanton',
                    },
                },
            },
            place: {
                address: {
                    country: 'Land',
                    region: 'Grossregions BFS',
                    canton: 'Kanton',
                    municipality: 'Gemeinde',
                    plz: 'PLZ',
                },
                location: {
                    name: 'Lage',
                },
            },
            constructionTime: {
                competition: 'Entscheid Wettbewerb',
                moveIn: 'Fertigstellung',
                planningStart: 'Planungsbeginn',
                constructionStart: 'Baubeginn',
                duration: 'Bauzeit (Monate)',
            },
        },
        floor: {
            floorTypes: {
                UNDER_GROUND: 'UG',
                GROUND: 'EG',
                ABOVE_GROUND: 'OG',
            },
            totalArea: 'GF in m²',
            area: 'NF in m²',
            utilisation: 'Nutzung',
            number: 'Anzahl',
            'add-underground': 'Geschoss unterhalb hinzufügen',
            'add-overground': 'Geschoss oberhalb hinzufügen',
            'delete-floor': 'Geschoss löschen',
            'delete-floor-area': 'Nutzung löschen',
            'add-floor-function': 'Nutzung hinzufügen',
        },
    },
    projects: {
        details: {
            published: {
                true: 'Öffentlich',
                false: 'Privat',
            },
        },
        create: {
            title: 'Neues Projekt',
        },
        edit: {
            title: 'Bearbeiten',
        },
        creator: {
            title: 'Neues Projekt',
            'data-sheet': {
                title: 'Datenblatt',
                source: 'Projektnachweis',
                members: 'Beteiligte',
                tags: 'Tags',
                descriptions: 'Beschriebe',
                'linked-projects': 'Verlinkte Projekte',
            },
            'measurement-sheet': {
                title: 'Flächen und Volumen',
                label: 'Fläche / Menge',
                sia: {
                    title: 'SIA Mengen',
                },
            },
            'building-sheet': {
                title: 'Gebäude',
                'discard-3d-model': '3D Model verwerfen',
                dialog: {
                    'delete-input-file': {
                        title: 'Datei löschen',
                        description:
                            'Die Projektdatei wird gelöscht und kann nicht mehr wiederhergestellt werden. Wollen Sie fortfahren?',
                        confirm: '$t(common:yes)',
                        cancel: '$t(common:no)',
                    },
                },
            },
            'cost-sheet': {
                title: 'Kennwerte',
                import: {
                    action: 'Einlesen',
                    dialog: {
                        error: {
                            title: 'Import ist fehlgeschlagen',
                            description:
                                'Es gab ein Problem beim Import des CSVs. Bitte versuchen Sie es noch einmal oder kontaktieren Sie CRB um das Format zu verifizieren.',
                            cancel: 'OK',
                        },
                        overrides: {
                            title: 'Kosten werden überschrieben',
                            description:
                                'Beim Importieren werden alle Kosten der Regulierung ersetzt. Wollen Sie trotzdem fortfahren?',
                            cancel: 'Abbrechen',
                            confirm: 'Fortfahren',
                        },
                        incorrect: {
                            title: 'Einige Kosten werden korrigiert',
                            description:
                                'Einige Kosten sind nicht korrekt. Diese werden beim Import automatisch korrigiert.',
                            cancel: 'Abbrechen',
                            confirm: 'OK',
                        },
                    },
                },
                columns: {
                    code: 'Code',
                    name: 'Bezeichnung',
                    'short-name': 'Kürzel',
                    quantity: 'Bezugsmenge',
                    value: 'Betrag',
                    checksum: 'Prüfsumme',
                    description: 'Beschreibung',
                },
                rows: {
                    quantity: 'Bezugsmenge',
                    'value-in-chf': 'Betrag in CHF',
                    description: 'Beschreibung',
                },
                'cost-index': {
                    level: 'Preisstand',
                },
                tabs: {
                    BKP: 'BKP',
                    'eBKP-H': 'eBKP-H',
                    'eBKP-T(A)': 'eBKP-T(A)',
                    'eBKP-T(B)': 'eBKP-T(B)',
                    'eBKP-H2020(A)': 'eBKP-H (A) 2020',
                    'eBKP-H2020(B)': 'eBKP-H (B) 2020',
                },
                'calculated-regulation': {
                    koa: 'Anlagekosten',
                    kob: 'Bauwerkskosten',
                    koe: 'Erstellungskosten',
                    kog: 'Gebäudekosten',
                },
            },
            'energy-sheet': {
                title: 'Energie',
            },
            'media-sheet': {
                title: 'Bilder und Pläne',
                table: {
                    nr: 'Nr.',
                    name: 'Name',
                },
                'main-image': 'Vorschaubild',
                upload: '+ Hochladen',
                filename: 'Dateiname',
                'delete-image': 'Bild löschen',
                'update-image-name': 'Bildname ändern',
                'limit-reached':
                    'Sie haben die maximale Anzahl von Bildern erreicht, die für ein Projekt hochgeladen werden können.',
                'file-too-big': 'Eine der ausgewählten Dateien ist zu gross',
                'file-too-long': 'Die maximal zulässige Abmessung für ein Bild beträgt 2400px x 2400px',
                'click-here': 'Hier klicken',
                or: 'oder',
                'drag-and-drop': 'drag & drop',
                'to-upload': 'um Dateien hochzuladen',
                'image-constraint': {
                    'accepted-types': 'Akzeptierte Bildtypen sind JPEG und PNG.',
                    'max-file-size': 'Die maximale Dateigrösse beträgt 10 MB.',
                    'resize-note': 'Bilder, die grösser als 2400x2400 sind, werden in der Grösse angepasst.',
                },
            },
        },
        list: {
            title: 'Projektliste',
            actions: {
                'document-new-project': 'Neues Projekt dokumentieren',
                'import-new-project': 'Neues Projekt importieren',
                'estimate-new-project': 'eBKP-H Kalkulator',
                'copy-project': 'Kopieren',
                'edit-project': 'Bearbeiten',
                'link-project': 'Verlinken',
                'delete-project': 'Löschen',
                'change-project-status': 'Status ändern',
                'lock-project-status': 'Veröffentlichen',
                'unlock-project-status': 'Unveröffentlichen',
                'export-projects': 'Excel exportieren',
                'elektrosmog-export-projects': 'Elektrosmog exportieren',
                'transfer-projects': 'Transferieren',
                'share-project': 'Teilen',
            },
            'project-id': 'PrID',
            'project-name': 'Name',
            'project-phase': 'Projektphase',
            'project-links': 'Siehe noch',
            'project-postcode': 'PLZ',
            'project-city': 'Gemeinde',
            'project-canton': 'Kanton',
            'project-year': 'Fertigstellungsdatum',
            'project-type': 'Objektart',
            'project-type-partners': 'Werkkategorie',
            'project-status': 'Status',
            'empty-list': 'Keine Projekte vorhanden.',
            'no-access-to-projects': `Sie haben sich für dir Gratisversion des Objektkalkulators entschieden und haben daher keine Projekte vorhanden. Upgraden Sie auf unsere "Small License" um Zugriff auf all Ihre geschätzten Projekte zu haben und zudem von weitern Funktionalitäten profitieren zu können! 044 123 44 55 oder per mail an crb@crb.ch`,
            'project-public': {
                true: 'Öffentlich',
                false: 'Privat',
            },
            navigation: {
                page: 'Seite',
                'of-page': 'von {{max}}',
            },
        },
        filter: {
            title: 'Projektliste filtern',
            apply: 'Filter anwenden',
            reset: 'Filter löschen',
            filters: {
                name: 'Name',
                source: 'Quelle',
                category: 'Werkkategorie',
                canton: 'Kanton',
                completionYear: 'Fertigstellungsjahr',
                regulations: 'Preisregelwerk',
            },
        },
    },
    actions: {
        'return-to-editor': 'Zurück zur Projektliste',
        'everything-up-to-date': 'Alle Daten sind gespeichert.',
        save: 'Speichern',
        finish: 'Abschliessen',
    },
    share: {
        title: 'Projekt teilen',
        form: {
            title: 'Projekt "{{name}}" teilen',
            userId: 'Benutzer ID',
            canView: 'Kann in der Projektliste sehen',
            canEdit: 'Kann das Projekt bearbeiten',
        },
    },
    'confirm-deletion': {
        confirm: 'Bestätigen',
        cancel: 'Abbrechen',
        title: 'Projekt löschen',
        description:
            'Sind Sie sicher, die ausgewählten Projekte zu löschen? Die Änderungen können nicht rückgängig gemacht werden.',
    },
    'bkp-to-ebkph-wizard': {
        steps: {
            'general-settings': 'Grundeinstellungen',
            volumes: 'Volumen Auswertung',
            prices: 'Erzeugen von Preisen',
            plausibility: 'Plausibilitätsprüfung',
        },
        'volume-evaluation': {
            steps: {
                upload: 'Upload',
                control: 'Kontrolle',
                terrain: 'Terrain',
                model: 'Modell',
                results: 'Ergebnisse',
            },
            dialog: {
                'delete-input-file': {
                    title: 'Datei löschen',
                    description:
                        'Die Wizard Datei wird gelöscht und kann nicht mehr wiederhergestellt werden. Wollen Sie fortfahren?',
                    confirm: '$t(common:yes)',
                    cancel: '$t(common:no)',
                },
            },
            'building-plan-importer': {
                procedure: {
                    ifc: {
                        title: '3D - IFC: Erstellen Sie ein 3D Modell mit der Verwendung der Modellierungsrichtlinien (<1>LINK</1>)',
                        description:
                            'Dieses Modell können Sie als IFC File speichern und hochladen. In diesem Fall springen Sie zum Schritt 4. (Letzter Bullet-Point)',
                    },
                    dxf: {
                        title: '2D - DXF: Erstellen Sie mit uns ein 3D-Modell aus Ihren Grundrissen',
                        description:
                            'Aus den Umrissen von Gebäuden bauen Sie in den nächsten Schritten Ihr Projekt in 3D auf. Mit Hilfe dieses Modells werden dann automatisch Flächen, Volumen und andere Kennzahlen berechnet.',
                    },
                },
                cad: {
                    ifc: {
                        title: '3D - IFC:',
                        description: 'Springen Sie zum Schritt 4. (Letzter Bullet-Point)',
                    },
                    dxf: {
                        title: '2D - DXF: Öffnen Sie die Pläne in Ihrem CAD',
                        description:
                            'Zeichnen Sie alle Gebäude- und Parzellenumrisse mit der Polylinie Ihres CAD-Programms. Folgen Sie unserer Wegleitung, wenn Sie sich nicht sicher sind.',
                    },
                },
                inputfile: {
                    ifc: {
                        title: '3D - IFC:',
                        description: 'Springen Sie zum Schritt 4. (Letzter Bullet-Point)',
                    },
                    dxf: {
                        title: '2D - DXF: Exportieren Sie die Umrisse als DXF',
                        description:
                            'Das exportierte DXF darf ausser den Umrissen keine weiteren Elemente wie Wände etc. enthalten.',
                    },
                },
                upload: {
                    title: 'Laden Sie die DXF- oder IFC-Datei hoch',
                    description:
                        'Ziehen Sie Ihre DXF- oder IFC-Datei auf das Feld oben, oder klicken Sie auf «DXF oder IFC wählen»',
                },
                back: 'Zurück',
                next: 'Weiter',
                'choose-input-file': 'DXF oder IFC wählen',
                'file-too-large-upload-error':
                    'Die hochgeladene Datei ist zu gross. Bitte verwenden Sie eine Dateigrösse von weniger als {{param}}.',
                'reading-input-file-error':
                    'Die Datei konnte nicht gelesen werden. Stellen Sie sicher, dass die Datei unbeschädigt ist und nicht von einem anderen Programm benutzt wird und versuchen Sie es noch einmal.',
                'general-upload-error':
                    'Beim Hochladen der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie',
                'ifc-error':
                    'Etwas ging schief beim Hochladen des IFC Files. Bitte wechseln Sie den Tab oder navigieren Sie einen Schritt zurück und kommen Sie danach wieder auf diese Seite zurück, um das Hochladen erneut auszuführen.',
            },
            'import-control-form': {
                title: 'Import kontrollieren',
                'description-1': 'Stimmen die Längenangaben der Umrisse?',
                'description-2':
                    'Kontrollieren Sie, ob die Masse realistisch sind, und passen Sie - wenn notwendig - die Skalierung an, oder laden Sie den Plan erneut hoch.',
                scale: 'Skalierung',
                optimizations: 'Optimierungen wurden angewendet',
                'new-upload': 'Neu hochladen',
            },
            'terrain-control-form': {
                'terrain-title': 'Terrain kontrollieren',
                'terrain-description': 'Kontrollieren Sie - wenn notwendig - die Auswahl des Terrains.',
                'floor-title': '{{buildingName}}: {{floorName}} wählen',
                'floor-description': 'Wählen Sie die Umrisse des Geschosses auf dem Plan oder in der Tabelle.',
                name: 'Name',
                area: 'Fläche',
                accept: 'Bestätigen',
                cancel: 'Abbrechen',
                contour: 'Umriss',
            },
            'model-creation-form': {
                title: 'Modell aufbauen',
                description: 'Fügen Sie Teilgebäude und Geschosse zum 3D-Modell hinzu.',
                name: 'Name',
                height: 'Höhe (m)',
                actions: 'Aktionen',
                area: 'Fläche (m²)',
                submit: 'Abschliessen',
                fa: 'FA',
                'fa-long': 'Geschossfläche (GF)',
                muah: 'MUAH',
                'muah-long': 'Nutzfläche (NF) zu H',
                usa: 'USA',
                'usa-long': 'Nutzfläche (NF)',
                roof: 'Dach',
                'h-required-tooltip': 'Bitte wählen Sie die Umrisse aus und geben Sie die Nutzfläche (NF) zu H an.',
                'add-building': 'Gebäude hinzufügen',
                floors: 'Geschosse',
                'relative-elevation': 'Relative Höhe Erdgeschoss',
            },
            results: {
                name: 'Name',
                height: 'Höhe (m)',
                area: 'Fläche (m²)',
                'measurement-rule': 'Messregel',
                'short-name': 'Kurzname',
                value: 'Wert',
                sa: 'Grundstücksfläche (GSF)',
                fa: 'Geschossfläche (GF)',
                fga: 'Fläche Aussenwand',
                bra: 'Fläche Bedachung Gebäude',
                muah: 'Nutzfläche (NF) zu H',
                esa: 'Bearbeitete Umgebungsfläche (BUF)',
                usa: 'Nutzfläche (NF)',
                bv: 'Volumen Gebäude (GV)',
                fgma: 'Modell-Fläche Aussenwand',
                x: 'Fremde Volumen auf dem Grundstück (Fremdkörper)',
                muahv: 'Nutzvolumen (NV) zu H',
            },
        },
        'general-settings': {
            steps: {
                'mapping-selection': 'Nutzungsspezifische Anlage Gebäude',
                BKP: 'Auswahl Referenzprojekte $t(editor:regulation:BKP)',
                'eBKP-H2020(A)': 'Auswahl Referenzprojekte $t(editor:regulation:eBKP-H)',
            },
            'none-available': {
                BKP: 'Keine $t(editor:regulation:BKP) Referenzprojekte verfügbar',
                'eBKP-H': 'Keine $t(editor:regulation:eBKP-H) Referenzprojekte verfügbar',
                'eBKP-H2020(A)': 'Keine $t(editor:regulation:eBKP-H) Referenzprojekte verfügbar',
            },
        },
        'regulation-mapping-selection': {
            confirm:
                'Sind Sie sicher, dass Sie Ihre Eingabe ändern möchten? Alle Volumen- und Preisdaten im Umrechnungsassistenten gehen verloren.',
            'help-text': `
            Bitte geben Sie unten an, ob Ihr Projekt für die Hauptgruppe H "Nutzungsspezifische
            Anlage Gebäude" des $t(editor:regulation:eBKP-H) relevante Preise enthält?`,
            option: {
                'tree-no-h': 'Nein',
                'tree-with-h': 'Ja',
            },
            headers: {
                'code-description': 'Code und Bezeichnung',
                total: 'CHF Total',
                'h-value': 'CHF Anteil H',
                'h-percentage': '% Anteil H',
            },
        },
        'price-regeneration': {
            'all-chapters': 'Alle',
            'data-invalid':
                'Es fehlen Eingaben im Assistenten. Bitte ergänzen Sie diese, bevor Sie die Plausibilitätsprüfung beginnen.',
            'cost-value': {
                value: 'Betrag CHF',
                'h-value': 'H CHF',
                'percentage-of': '% von',
            },
            'distribution-in-ebkph': 'Verteilung nach $t(editor:regulation:eBKP-H) in %',
            headers: {
                status: 'Status',
                code: 'Code',
                regulation: 'Regulation',
                lock: 'Sperren',
            },
            'lock-all': 'Alle',
            'progress-in-percentage': 'Fortschritt in %',
            states: {
                checked: 'Erledigt',
                'to-be-checked': 'Zu überprüfen',
                'can-be-checked': 'Kann überprüft werden',
                unknown: 'Unbekannt',
            },
            steps: {
                'bkp-assignment': '$t(editor:regulation:BKP) Aufteilung',
            },
            total: 'Total',
            filters: {
                checked: 'Erledigt',
                'to-be-checked': 'Zu überprüfen',
                'can-be-checked': 'Kann überprüft werden',
                'all-nodes': 'Alle Endknoten',
                apply: 'Anwenden',
                nodes: 'Endknoten',
            },
            'bkp-override': {
                button: '$t(editor:regulation:BKP)-Preise überschreiben',
                dialog: {
                    title: '$t(editor:regulation:BKP)-Preise überschreiben',
                    description:
                        'Wenn Sie diese Funktion aktivieren, werden Ihre ursprünglichen $t(editor:regulation:BKP)-Preise durch die unten definierten Preise überschrieben. Sind Sie sicher, dass Sie Ihre $t(editor:regulation:BKP)-Preise überschreiben möchten?',
                    confirm: 'Ja, $t(editor:regulation:BKP)-Preise überschreiben',
                    cancel: 'Nein, $t(editor:regulation:BKP)-Preise unverändert lassen',
                },
            },
        },
        plausibility: {
            headers: {
                'short-name': 'Kürzel',
                'code-description': 'Code und Bezeichnung',
                'reference-quantity': 'Bezugsmenge',
                amount: 'Betrag',
                'reference-projects-comparison': 'Vergleich Referenzprojekte',
            },
            info: {
                'reference-projects': '\u2713 mit {{param}} Referenzprojekt(en) verglichen',
                'connection-3D-model': '\u2713 mit 3D Modell verbunden',
                'connection-bcc-distribution': '\u2713 mit $t(editor:regulation:BKP) verbunden',
                'data-missing':
                    'Es fehlen Eingaben im Assistenten. Bitte ergänzen Sie diese, bevor Sie den Assistenten beenden.',
            },
            complete: 'Abschliessen',
        },
        complete: {
            title: 'Konvertierung beenden?',
            description:
                'Sind Sie sicher, dass Sie den Assistenten beenden möchten? Sie können die Werte dann nicht mehr bearbeiten.',
            error: 'Etwas ist schief gelaufen, bitte kontaktieren Sie',
        },
    },
    'box-plot': {
        range: '{{min}} - {{max}} {{unit}}',
        median: '{{median}} {{unit}}',
        'no-data': 'Keine Daten',
    },
    'regulation-statistic': {
        mean: 'Mittelwert',
    },
    'estimation-wizard': {
        'cost-estimation': {
            'save-pdf-export': 'Speichern & PDF exportieren',
            'save-close': 'Speichern & Schliessen',
            cancel: 'Abbrechen',
            'download-input-file': 'IFC - oder DXF - Datei exportieren',
        },
        'reference-projects': {
            'load-project-ids': 'Referenz Projekte Anwenden',
            'load-selected-project-ids': 'Ausgewählte übernehmen',
            'load-short-list': 'Kurzliste übernehmen',
            'no-data': 'Es sind keine Referenzprojekte verfügbar',
            'load-project-ids-error':
                'Beim Laden der Referenzprojekte ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie CRB um das Problem zu beheben.',
        },
        categories: {
            'select-categories': 'Kategorien auswählen',
            all: 'Alle',
            'total-costs': 'Gesamtkosten',
        },
    },
    tagEditor: {
        description: '',
        search: 'Suche',
        addTag: 'Tag hinzufügen',
        replace: {
            text: 'Der ausgewählte Tag ist bereits in Gebrauch. \nWählen Sie aus durch welchen Tag der ausgewählte Tag ersetzt werden soll:',
            confirm: 'Ersetzen',
            cancel: 'Abbrechen',
        },
    },
};
