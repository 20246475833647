import {
    faClone,
    faFileExport,
    faFilter,
    faLock,
    faPaperPlane,
    faPencilAlt,
    faShare,
    faTimes,
    faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import type { TFunction } from 'i18next';
import { keyBy, max, min, set } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { flattenTree, useCantons, useClassficiationTree } from '../../effects';
import type { UserInfo } from '../../types';
import { LinkButton, MenuButton } from '../Buttons';
import { ProjectShare } from '../ProjectShare';
import { IProjectFilterData, ProjectFilterMenu } from './ProjectFilterMenu';
import { createProjectFilter } from './utils';

interface Props {
    className?: string;
    user?: UserInfo;
    projects?: ProjectOverview[];
    staticData?: StaticData | null;
    selectedProjects: ProjectOverview[];
    copyProject: (id: ID) => unknown;
    editProject: (id: ID) => unknown;
    deleteProject: (projects: ProjectOverview[]) => unknown;
    toggleVisibility: (projects: ProjectOverview[]) => unknown;
    exportProjects?: (projects: ProjectOverview[], type: 'excel' | 'elektrosmog') => unknown;
    transferProjects: (projects: ProjectOverview[]) => unknown;
    onFilter: (filter: (project: ProjectOverview) => boolean) => void;
    t: TFunction;
    disableFilterPersistance?: boolean;
}

const NO_BUILDING_CLASS: StaticBuildingClassification[] = [];
const NO_PROJECTS: ProjectOverview[] = [];

export const ProjectActions: React.FC<Props> = ({
    className,
    user,
    projects = NO_PROJECTS,
    staticData,
    selectedProjects,
    disableFilterPersistance,
    copyProject,
    editProject,
    deleteProject,
    transferProjects,
    exportProjects,
    toggleVisibility,
    onFilter,
    t,
}) => {
    const history = useHistory();

    const classificationTree = useClassficiationTree(
        staticData?.buildingClassification.partners ?? NO_BUILDING_CLASS,
        true
    );
    const flattenedClassificationTree = React.useMemo(
        () => classificationTree.flatMap((tree) => flattenTree(tree)),
        [classificationTree]
    );

    /*
     * Returns a dictonary of each element of the tree (including parents and children) keyed by the id.
     */
    const buildingClassificationTreeMap = React.useMemo(
        () => keyBy(flattenedClassificationTree, (c) => c.id),
        [flattenedClassificationTree]
    );

    const cantons = useCantons(staticData);

    const [minYear, maxYear] = useMemo(() => {
        const years = projects
            .map((p) => new Date(p.moveIn).getFullYear())
            .map((value) => (value < 100 ? 2000 + value : value));

        const minYear = min(years);
        const maxYear = max(years);

        return [minYear, maxYear];
    }, [projects]);

    const [filterData, setFilterData] = React.useState<IProjectFilterData>();

    // Set initial filter data
    React.useEffect(() => {
        if (!disableFilterPersistance && minYear && maxYear && !filterData) {
            const newFilter: IProjectFilterData = {
                name: '',
                source: '',
                category: undefined,
                canton: undefined,
                regulations: {},
                yearMin: minYear,
                yearMax: maxYear,
            };

            const params = new URLSearchParams(history.location.search);
            const filters = params.getAll('filter');

            filters
                .map((f) => f.split('=') as [keyof IProjectFilterData, string])
                .forEach(([key, value]) => set(newFilter, key, transformValue(value) ?? newFilter[key]));

            newFilter.yearMin = Math.max(Math.min(maxYear, newFilter.yearMin), minYear);
            newFilter.yearMax = Math.min(Math.max(minYear, newFilter.yearMax), maxYear);

            setFilterData(newFilter);
        }
    }, [history.location.search, minYear, maxYear, filterData, disableFilterPersistance]);

    // Set filters in URL
    React.useEffect(() => {
        if (filterData && !disableFilterPersistance) {
            const { regulations: regulationsFilter, ...restFilter } = filterData;

            const hasFilter =
                Object.entries(restFilter).some(([_, value]) => !!value) ||
                Object.entries(regulationsFilter).some(([_, value]) => !!value);

            if (hasFilter) {
                const params = new URLSearchParams(history.location.search);

                params.delete('filter');

                const appender = appendFilter(params);
                Object.entries(restFilter)
                    .filter(([, value]) => !!value)
                    .forEach(([name, value]) => appender(name, value));

                Object.entries(regulationsFilter)
                    .filter(([, value]) => !!value)
                    .forEach(([name, value]) => appender(name, value));

                const search = params.toString();

                if (history.location.search !== search) {
                    history.push({
                        ...history.location,
                        search: params.toString(),
                    });
                }
            }
        }
    }, [filterData, history, disableFilterPersistance]);

    // Remove filters in URL when unmount
    React.useEffect(
        () => () => {
            if (!disableFilterPersistance) {
                const params = new URLSearchParams(history.location.search);

                params.delete('filter');

                history.location.search = params.toString();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [disableFilterPersistance]
    );

    React.useEffect(() => {
        if (filterData) {
            onFilter(createProjectFilter(filterData, buildingClassificationTreeMap));
        }
    }, [filterData, buildingClassificationTreeMap, onFilter]);

    const firstSelectedProject = selectedProjects[0] as ProjectOverview | undefined;
    const firstSelectedProjectId = firstSelectedProject?.id;

    const copy = useCallback(() => {
        if (firstSelectedProjectId) copyProject(firstSelectedProjectId);
    }, [copyProject, firstSelectedProjectId]);
    const edit = useCallback(() => {
        if (firstSelectedProjectId) editProject(firstSelectedProjectId);
    }, [editProject, firstSelectedProjectId]);
    const dele = useCallback(() => {
        deleteProject(selectedProjects);
    }, [deleteProject, selectedProjects]);
    const toggleStatus = useCallback(() => {
        toggleVisibility(selectedProjects);
    }, [toggleVisibility, selectedProjects]);
    const lockProjects = useCallback(() => {
        const unlockedProjects = selectedProjects.filter(({ userPublished }) => !userPublished);
        toggleVisibility(unlockedProjects);
    }, [toggleVisibility, selectedProjects]);
    const unlockProjects = useCallback(() => {
        const lockedProjects = selectedProjects.filter(({ userPublished }) => userPublished);
        toggleVisibility(lockedProjects);
    }, [toggleVisibility, selectedProjects]);
    const handleExcelProjectExport = useCallback(() => {
        exportProjects?.(selectedProjects, 'excel');
    }, [exportProjects, selectedProjects]);
    const handleElektrosmogProjectExport = useCallback(() => {
        exportProjects?.(selectedProjects, 'elektrosmog');
    }, [exportProjects, selectedProjects]);
    const handleProjectTransfer = useCallback(() => {
        transferProjects(selectedProjects);
    }, [transferProjects, selectedProjects]);

    const [projectToShare, setProjectToShare] = useState<ProjectOverview>();
    const openProjectShare = useCallback(() => setProjectToShare(selectedProjects[0]), [selectedProjects]);
    const closeProjectShare = useCallback(() => setProjectToShare(undefined), []);
    const ShareButton = useCallback(
        ({ disabled }) => (
            <LinkButton
                className="mr-3 mt-3"
                onClick={openProjectShare}
                disabled={selectedProjects.length !== 1 || disabled}
                noUnderline={true}
                testId="share-project"
            >
                {t('editor:projects:list:actions:share-project')}
                &nbsp;
                <FontAwesomeIcon icon={faShare} />
            </LinkButton>
        ),
        [openProjectShare, selectedProjects, t]
    );

    const menuProps = React.useMemo(
        () => ({
            projects,
            classifications: flattenedClassificationTree,
            cantons,
            filters: filterData,
            onFilterData: setFilterData,
        }),
        [projects, flattenedClassificationTree, cantons, filterData]
    );

    const isAdmin = user?.roles.includes('adminwmo');
    const isElektroWMO = user?.roles.includes('elektrowmo');
    const isLektorWMO = user?.roles.includes('lektorwmo');

    return (
        <div className={classnames('editor-actions', className)}>
            <LinkButton
                className="mr-3 mt-3"
                onClick={copy}
                disabled={selectedProjects.length !== 1}
                noUnderline={true}
                testId="copy"
            >
                {t('editor:projects:list:actions:copy-project')} <FontAwesomeIcon icon={faClone} />
            </LinkButton>

            <LinkButton
                className="mr-3 mt-3"
                onClick={edit}
                disabled={selectedProjects.length !== 1}
                noUnderline={true}
                testId="edit"
            >
                {t('editor:projects:list:actions:edit-project')} <FontAwesomeIcon icon={faPencilAlt} />
            </LinkButton>

            <LinkButton
                className="mr-3 mt-3"
                onClick={dele}
                disabled={selectedProjects.length === 0}
                noUnderline={true}
                testId="delete"
            >
                {t('editor:projects:list:actions:delete-project')} <FontAwesomeIcon icon={faTimes} />
            </LinkButton>

            {selectedProjects.length <= 1 && (
                <LinkButton
                    className="mr-3 mt-3"
                    onClick={toggleStatus}
                    disabled={!selectedProjects.length}
                    noUnderline={true}
                    testId="change-status"
                >
                    {t('editor:projects:list:actions:change-project-status')}&nbsp;
                    {selectedProjects.length === 1 && selectedProjects[0]?.userPublished ? (
                        <FontAwesomeIcon icon={faUnlock} />
                    ) : (
                        <FontAwesomeIcon icon={faLock} />
                    )}
                </LinkButton>
            )}

            {selectedProjects.length > 1 && (
                <>
                    <LinkButton className="mr-3 mt-3" onClick={lockProjects} noUnderline={true} testId="lock-status">
                        {t('editor:projects:list:actions:lock-project-status')}&nbsp;
                        <FontAwesomeIcon icon={faLock} />
                    </LinkButton>
                    <LinkButton
                        className="mr-3 mt-3"
                        onClick={unlockProjects}
                        noUnderline={true}
                        testId="unlock-status"
                    >
                        {t('editor:projects:list:actions:unlock-project-status')}&nbsp;
                        <FontAwesomeIcon icon={faUnlock} />
                    </LinkButton>
                </>
            )}

            <LinkButton
                className="mr-3 mt-3"
                onClick={handleExcelProjectExport}
                disabled={selectedProjects.length <= 0}
                noUnderline={true}
                testId="export-projects"
            >
                {t('editor:projects:list:actions:export-projects')}
                &nbsp;
                <FontAwesomeIcon icon={faFileExport} />
            </LinkButton>

            {(isAdmin || isElektroWMO || isLektorWMO) && (
                <LinkButton
                    className="mr-3 mt-3"
                    onClick={handleElektrosmogProjectExport}
                    disabled={selectedProjects.length <= 0}
                    noUnderline={true}
                    testId="elektrosmog-export-projects"
                >
                    {t('editor:projects:list:actions:elektrosmog-export-projects')}
                    &nbsp;
                    <FontAwesomeIcon icon={faFileExport} />
                </LinkButton>
            )}

            <LinkButton
                className="mr-3 mt-3"
                onClick={handleProjectTransfer}
                disabled={selectedProjects.length === 0}
                noUnderline={true}
                testId="transfer-projects"
            >
                {t('editor:projects:list:actions:transfer-projects')}
                &nbsp;
                <FontAwesomeIcon icon={faPaperPlane} />
            </LinkButton>

            <ProjectShare
                projectId={selectedProjects.length === 1 ? selectedProjects[0]?.id : projectToShare?.id}
                project={projectToShare}
                isOpen={!!projectToShare}
                Button={ShareButton}
                onExit={closeProjectShare}
            />

            <MenuButton
                Menu={ProjectFilterMenu}
                menuProps={menuProps}
                disabled={projects === NO_PROJECTS || !staticData || !filterData}
            >
                <FontAwesomeIcon icon={faFilter} />
            </MenuButton>
        </div>
    );
};

function transformValue(value: string | null) {
    if (value == null || !value.length) {
        return undefined;
    }

    if (/\d+/.test(value)) return parseInt(value);
    if (/\d+\.\d+/.test(value)) return parseFloat(value);
    if (value === 'true' || value === 'false') return value === 'true';

    return value;
}

function appendFilter(params: URLSearchParams) {
    const appender = (name: string, value: unknown): void => {
        if (typeof value === 'object' && value) {
            Object.entries(value).forEach(([subName, subValue]) => appender(`${name}.${subName}`, subValue));
        }

        if (typeof value === 'number') params.append('filter', `${name}=${value}`);
        else if (value) params.append('filter', `${name}=${value}`);
    };

    return appender;
}
