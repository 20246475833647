export default {
    name: 'Editor delle opere',
    'object-calculator': 'Calcolatore di oggetti',
    language: 'Lingua della documentazione',
    'leave-editor': "Vuoi abbandonare l'editor senza salvare?",
    'open-ebkph-wizard': 'Converti in $t(editor:regulation:eBKP-H)',
    'close-wizard': 'Chiudere la procedura guidata',
    'wizard-underway': {
        title: 'Attenzione!',
        message:
            'Attenzione: Hai già iniziato a convertire questo progetto nella procedura guidata. Qualsiasi modifica dei valori di riferimento comporterà il ripristino della procedura guidata di conversione.',
        'save-anyway': 'Registrare comunque',
        cancel: 'Cancellare',
    },
    'arithmetically-incorrect-error-message':
        "Gli importi $t(editor:regulation:BKP) delle sottocategorie devono sommarsi all'importo della categoria. Si prega di correggere gli input prima di eseguire la conversione.",
    'input-file-import': {
        'incorrect-input-file-type': {
            dialog: {
                title: 'Tipo di file sbagliato',
                description: 'Il tipo di file non sembra essere corretto. Funzionano solo i file DXF o IFC.',
                confirm: 'Aprire comunque il file',
                cancel: 'Scegliere un altro file',
            },
        },
    },
    regulation: {
        BKP: 'CCC',
        'eBKP-H': 'eCCC-E',
        'eBKP-T(A)': 'eCCC-GC(A)',
        'eBKP-T(B)': 'eCCC-GC(B)',
        'eBKP-H2020(A)': 'eCCC-E (A) 2020',
        'eBKP-H2020(B)': 'eCCC-E (B) 2020',
    },
    pages: {
        data: 'Scheda tecnica',
        measurements: 'Aree e volumi',
        buildings: 'Edifici',
        costs: 'Valori di riferimento',
        energy: 'Energia',
        media: 'Piani e immagini',
        wizard: 'Wizard',
    },
    project: {
        parameters: {
            id: 'ID',
            projectId: 'PrID',
            name: 'Nome',
            creator: 'Utente-ID',
            owner: 'Ditta',
            buildingClassCrb: {
                id: 'Tipo di opera CRB OAG',
            },
            buildingClassPartners: {
                id: 'Tipo di opera werk-material',
            },
            greaterRegion: { id: 'Regione UST' },
            type: 'Tipo di proprietà',
            status: 'Stato',
            alternativeSource: 'Fonte alternativa',
            linkedProjects: 'Progetti collegati',
            adminStatus: 'Stato Pubblicazione',
            source: {
                source: 'Indicazione riferimento del progetto',
                catalogue: 'Catalogo/registro/indice',
                internalNumber: 'N° interno/N° indice',
                publicationDate: 'Data di pubblicazione',
                dataSource: 'Fonte di dati',
            },
            descriptions: {
                generalDescription: 'Informazioni sul progetto',
                usageConcept: 'Programa spaziale',
                construction: 'Costruzioni',
                buildingTechnology: 'Impiantistica',
                materials: 'Materiali',
                design: 'Design',
                energy: 'Energia',
                userComfort: 'Comfort di utilizzo',
                organisation: 'Organizzazione del progetto',
            },
            parameters: {
                constructionMethod: 'Tipo di costruzione',
                constructionMode: 'Costruzione',
                constructionMeasure: 'Tipo di intervento',
                constructionOwner: 'Committente',
                projectPhase: 'Fase del progetto',
            },
            participants: {
                name: 'Nome',
                role: 'Ruolo',
                'delete-participant': 'Rimuovi',
                'add-participant': 'Aggiungi operatore/i del progetto',
            },
            projectTags: {
                title: 'Etichette',
                projectTagName: "Nome dell'etichetta",
                deleteProjectTag: 'Cancellare',
                addProjectTag: 'Aggiungi etichetta',
                isNew: 'Creare una nuova etichetta',
                isPublic: 'Pubblico',
                isPrivate: 'Privato',
                showTags: 'Mostra i tag',
                tagEditor: {
                    title: 'Tag del progetto',
                },
                replaceDelete: {
                    title: 'Sostituire il tag',
                    description:
                        'Il tag viene sostituito con quello selezionato e quindi eliminato. \nSi desidera davvero sostituire il tag?',
                    cancel: 'Annullamento',
                    confirm: 'Sostituire',
                },
                confirmDelete: {
                    title: 'Cancellare il tag',
                    description:
                        "Il tag non sarà più disponibile dopo l'eliminazione. \nVuoi davvero eliminare il tag?",
                    cancel: 'Annullamento',
                    confirm: 'Cancellare',
                },
            },
            floorUsages: {
                title: 'Utilizzi',
                tagName: 'Usage',
                regulation: 'Utilizzo',
                area: 'Area',
                deleteFloorUsage: 'Cancellare',
                addFloorUsage: 'Aggiungere un utilizzo',
                isNew: 'Creare un nuovo utilizzo',
                isPublic: 'Pubblico',
                isPrivate: 'Privato',
                tags: {
                    showTags: 'Mostra i tag',
                    tagEditor: {
                        title: 'Tag del progetto',
                    },
                    replaceDelete: {
                        title: 'Sostituire il tag',
                        description:
                            'Il tag viene sostituito con quello selezionato e quindi eliminato. \nSi desidera davvero sostituire il tag?',
                        cancel: 'Annullamento',
                        confirm: 'Sostituire',
                    },
                    confirmDelete: {
                        title: 'Cancellare il tag',
                        description:
                            "Il tag non sarà più disponibile dopo l'eliminazione. \nVuoi davvero eliminare il tag?",
                        cancel: 'Annullamento',
                        confirm: 'Cancellare',
                    },
                },
            },
            functionalUnits: {
                title: 'Unità funzionale',
                amount: 'Quantità',
                functionalUnit: 'Unità funzionale',
                deleteFunctionalUnit: 'Rimuovi',
                addFunctionalUnit: 'Aggiungi unità funzionale',
                tags: {
                    showTags: 'Mostra i tag',
                    tagEditor: {
                        title: "Tag dell'unità funzionale",
                    },
                    replaceDelete: {
                        title: 'Sostituire il tag',
                        description:
                            'Il tag viene sostituito con quello selezionato e quindi eliminato. \nSi desidera davvero sostituire il tag?',
                        cancel: 'Annullamento',
                        confirm: 'Sostituire',
                    },
                    confirmDelete: {
                        title: 'Cancellare il tag',
                        description:
                            "Il tag non sarà più disponibile dopo l'eliminazione. \nVuoi davvero eliminare il tag?",
                        cancel: 'Annullamento',
                        confirm: 'Cancellare',
                    },
                },
            },
            projectSpecificReadings: {
                statutoryPlanningFramework: 'Condizioni generali del diritto della construzione',
                plotRatio: 'Indice di sfruttamento',
                zoneAllocation: 'Appartenenza zone',
                siteMasterplan: 'Piano particolareggiato',
                plotRatioBonus: 'Bonus sfruttamento',
                mobility: 'Mobilità',
                parkingSpaces: 'Posteggi',
                cycleParkingSpaces: 'Posteggio biciclette',
                vehicleChargingStations: 'Stazione di carica, elettrica',
            },
            projectAddress: {
                city: 'Luogo',
                streetName: 'Via',
                streetNumber: 'No.',
                bfsNumber: 'Numero UST',
                latitude: 'Latitudine',
                longitude: 'Longitudine',
                plusCodes: 'Google Plus Codes',
                egidNumber: 'Identificatore federale dell’edificio (EGID)',
                additional: 'Supplemento',
                location: {
                    municipality: 'Comune',
                    country: 'Paese',
                    countries: {
                        switzerland: 'Svizzera',
                        liechtenstein: 'Liechtenstein',
                    },
                    canton: {
                        cantonName: 'Cantone',
                    },
                    position: 'Ubicazione',
                },
            },
            place: {
                address: {
                    country: 'Paese',
                    region: 'Regione UST',
                    canton: 'Cantone',
                    municipality: 'Comune',
                    plz: 'CPA',
                },
                location: {
                    name: 'Ubicazione',
                },
            },
            constructionTime: {
                competition: 'Decisione del concorso',
                moveIn: 'Ultimazione',
                planningStart: 'Inizio pianificazione',
                constructionStart: 'Inizio costruzione',
                duration: 'Durata di costruzione (Mesi)',
            },
        },
        floor: {
            floorTypes: {
                UNDER_GROUND: '-',
                GROUND: 'PT/0',
                ABOVE_GROUND: '',
            },
            totalArea: 'SP in m²',
            area: 'SU in m²',
            utilisation: 'Utilizzo',
            number: 'Numero',
            'add-underground': 'Aggiungi piano interrato',
            'add-overground': 'Aggiungi piano rialzato',
            'delete-floor': 'Rimuovi piano',
            'delete-floor-area': 'Rimuovi utilizzo',
            'add-floor-function': 'Aggiungi utilizzo',
        },
    },
    projects: {
        details: {
            published: {
                true: 'Pubblico',
                false: 'Privato',
            },
        },
        create: {
            title: 'Nuovo progetto',
        },
        edit: {
            title: 'Modifica',
        },
        creator: {
            title: 'Nuovo progetto',
            'data-sheet': {
                title: 'Scheda tecnica',
                source: 'Riferimento del progetto',
                members: 'Partecipanti',
                descriptions: 'Descrizioni',
                'linked-projects': 'Progetti collegati',
            },
            'measurement-sheet': {
                title: 'Aree e volumi',
                label: 'Superficie / Volume',
                sia: {
                    title: 'SIA importi',
                },
            },
            'building-sheet': {
                title: 'Edifici',
                'discard-3d-model': 'Scartare il modello 3D',
                dialog: {
                    'delete-input-file': {
                        title: 'Elimina file',
                        description:
                            'Il file di progetto è stato cancellato e non può più essere ripristinato. Volete continuare?',
                        confirm: '$t(common:yes)',
                        cancel: '$t(common:no)',
                    },
                },
            },
            'cost-sheet': {
                title: 'Valori di riferimento',
                import: {
                    action: 'Importare',
                    dialog: {
                        error: {
                            title: 'Importazione fallita',
                            description:
                                "Qualcosa é andato storto durante l'importazione del file CSV. Riprovare o contattare il supporto CRB per verificare la correttezza del formato.",
                            cancel: 'OK',
                        },
                        overrides: {
                            title: 'Sovrascrivere i costi esistenti',
                            description:
                                'Quando si importa, tutti i costi di regolazione vengono sostituiti. Vuoi ancora procedere?',
                            cancel: 'Abort',
                            confirm: 'Continua',
                        },
                        incorrect: {
                            title: 'Alcuni costi saranno corretti',
                            description:
                                "Alcuni costi non sono corretti. Questi saranno corretti automaticamente durante l'importazione.",
                            cancel: 'Cancella',
                            confirm: 'OK',
                        },
                    },
                },
                columns: {
                    code: 'Codice',
                    name: 'Designazione',
                    'short-name': 'Abbreviazione',
                    quantity: 'Quantità di riferimento',
                    value: 'Importo',
                    checksum: 'Somma di controllo',
                    description: 'Descrizione',
                },
                rows: {
                    quantity: 'Quantità di riferimento',
                    'value-in-chf': 'Importo in CHF',
                    description: 'Descrizione',
                },
                'cost-index': {
                    level: 'Livello di prezzo',
                },
                tabs: {
                    BKP: 'CCC',
                    'eBKP-H': 'eCCC-E',
                    'eBKP-T(A)': 'eCCC-GC(A)',
                    'eBKP-T(B)': 'eCCC-GC(B)',
                    'eBKP-H2020(A)': 'eCCC-E (A) 2020',
                    'eBKP-H2020(B)': 'eCCC-E (B) 2020',
                },
                'calculated-regulation': {
                    koa: "Costo dell'investimento",
                    kob: "Costo dell'opera",
                    koe: 'Costo di realizzazione',
                    kog: "Costo dell'edificio",
                },
            },
            'energy-sheet': {
                title: 'Energia',
            },
            'media-sheet': {
                title: 'Piani e immagini',
                table: {
                    nr: 'No.',
                    name: 'Nome',
                },
                'main-image': 'Miniatura',
                upload: '+ Carica',
                filename: 'Nome del file',
                'delete-image': 'Rimuovi immagine',
                'update-image-name': 'Rinomina immagine',
                'limit-reached':
                    'Hai raggiunto il numero massimo di immagini che possono essere caricate per un progetto.',
                'file-too-big': 'Uno dei file selezionati è troppo grande.',
                'file-too-long': "La dimensione massima consentita per un'immagine è 2400 px x 2400 px.",
                'click-here': 'Clicca qui',
                or: 'o',
                'drag-and-drop': 'trascina e rilascia',
                'to-upload': 'per caricare i file',
                'image-constraint': {
                    'accepted-types': 'I tipi di immagine accettati sono JPEG e PNG.',
                    'max-file-size': 'La dimensione massima del file è di 10 MB.',
                    'resize-note': 'Le immagini più grandi di 2400x2400 saranno ridimensionate.',
                },
            },
        },
        list: {
            title: 'Lista progetti',
            actions: {
                'document-new-project': 'Documenta il nuovo progetto',
                'import-new-project': 'Importare un nuovo progetto',
                'estimate-new-project': 'Calcolatore eCCC-E',
                'copy-project': 'Crea una copia',
                'edit-project': 'Modifica',
                'link-project': 'Collega',
                'delete-project': 'Elimina',
                'change-project-status': 'Cambia stato',
                'lock-project-status': 'Pubblicare',
                'unlock-project-status': 'Non pubblicato',
                'export-projects': 'Esportazione',
                'elektrosmog-export-projects': 'Esportazione Elektrosmog',
                'transfer-projects': 'Trasferimento',
                'share-project': 'Condividi',
            },
            'project-id': 'PrID',
            'project-name': 'Nome',
            'project-phase': 'Fase del progetto',
            'project-links': 'Vedi ancora',
            'project-postcode': 'CPA',
            'project-city': 'Comune',
            'project-canton': 'Cantone',
            'project-year': 'Ultimazione',
            'project-type': 'Tipo di opera',
            'project-type-partners': 'Tipo di opera werk-material',
            'project-status': 'Stato',
            'empty-list': 'Non ci sono progetti disponibili.',
            'no-access-to-projects': `Avete scelto la versione gratuita del calcolatore di oggetti e quindi non avete progetti disponibili. Passate alla nostra "Licenza Small" per avere accesso a tutti i vostri preziosi progetti e per beneficiare di funzionalità aggiuntive! 044 123 44 55 o per posta a crb@crb.ch`,
            'project-public': {
                true: 'Pubblico',
                false: 'Privato',
            },
            navigation: {
                page: 'Pagina',
                'of-page': 'di {{max}}',
            },
        },
        filter: {
            title: 'Filtrare i progetti',
            apply: 'Applicare il filtro',
            reset: 'Cancella filtro',
            filters: {
                name: 'Nome',
                source: 'Fonte',
                category: 'Categoria',
                canton: 'Cantone',
                completionYear: 'Anno di completamento',
                regulations: 'Regolamenti sui prezzi',
            },
        },
    },
    actions: {
        'return-to-editor': 'Torna alla lista dei progetti',
        'everything-up-to-date': 'Tutti i dati sono salvati.',
        save: 'Salvare',
        finish: 'Chiudere',
    },
    share: {
        title: 'Condividi il progetto',
        form: {
            title: 'Condividi Progetto "{{name}}"',
            userId: 'ID utente',
            canView: "Può essere visualizzato nell'elenco dei progetti",
            canEdit: 'Può modificare il progetto',
        },
    },
    'confirm-deletion': {
        confirm: 'Per confermare',
        cancel: 'Interrompi',
        title: 'Elimina progetto',
        description: 'Siete sicuri di voler lasciare la pagina? Tutte le modifiche vanno perse.',
    },
    'bkp-to-ebkph-wizard': {
        steps: {
            'general-settings': 'Impostazioni base',
            volumes: 'Valutazione del volume',
            prices: 'Rigenerazione prezzi',
            plausibility: 'Controllo di plausibilità',
        },
        'volume-evaluation': {
            steps: {
                upload: 'Upload',
                control: 'Controllare',
                terrain: 'Fondo',
                model: 'Modello',
                results: 'Risultati',
            },
            dialog: {
                'delete-input-file': {
                    title: 'Elimina file',
                    description:
                        'Il file del wizard verrà cancellato e non potrà essere ripristinato. Vuoi continuare?',
                    confirm: '$t(common:yes)',
                    cancel: '$t(common:no)',
                },
            },
            'building-plan-importer': {
                procedure: {
                    ifc: {
                        title: '3D - IFC: Creare un modello 3D utilizzando le Direttive di modellazione (<1>LINK</1>)',
                        description:
                            'Potete salvare e caricare questo modello come file IFC: in questo caso passate al punto 4 (ultimo punto)',
                    },
                    dxf: {
                        title: '2D - DXF: Create un modello 3D dalle vostre planimetrie',
                        description:
                            "Nei passi successivi, costruite il vostro progetto in 3D dai contorni dell'edificio. Con l'aiuto di questo modello, le superfici, i volumi e altre cifre chiave vengono calcolate automaticamente.",
                    },
                },
                cad: {
                    ifc: { title: '3D - IFC:', description: 'Passate al punto 4 (ultimo punto)' },
                    dxf: {
                        title: '2D - DXF: Aprite i piani nel vostro CAD',
                        description:
                            "Disegnate tutti i contorni dell'edificio e del terreno con la polilinea del vostro programma CAD. Seguite la nostra guida se non siete sicuri.",
                    },
                },
                inputfile: {
                    ifc: { title: '3D - IFC:', description: 'Passate al punto 4 (ultimo punto)' },
                    dxf: {
                        title: '2D - DXF: Esportate i contorni come DXF',
                        description:
                            'Il DXF esportato non deve contenere elementi diversi dai contorni, come muri, ecc.',
                    },
                },
                upload: {
                    title: 'Caricate il file DXF risp IFC',
                    description:
                        'Trascinate il vostro file DXF risp. IFC sul campo, o cliccate su *Selezionare DXF risp. IFC*',
                },
                back: 'Indietro',
                next: 'Avanti',
                'choose-input-file': 'Selezionare DXF o IFC',
                'file-too-large-upload-error':
                    'Il file scaricato è troppo grande. Si prega di utilizzare un file di dimensioni inferiori a {{param}}.',
                'reading-input-file-error':
                    'Il file non può essere letto. Assicuratevi che il file non sia danneggiato e che non sia usato da un altro programma e riprovate.',
                'general-upload-error':
                    'Si è verificato un errore durante il caricamento del file. Si prega di riprovare o di contattare',
                'ifc-error':
                    'Qualcosa è andato storto durante il caricamento del file IFC. Cambiare scheda o navigare indietro di un passo e poi tornare a questa pagina per caricare di nuovo.',
            },
            results: {
                name: 'Nome',
                height: 'Altezza (m)',
                area: 'Superficie (m²)',
                'measurement-rule': 'Regola di misurazione',
                'short-name': 'Abbreviazione',
                value: 'Valore',
                sa: 'Superficie del fondo (SF)',
                fa: 'Superficie di piano (SP)',
                fga: 'Superficie della parete esterna',
                bra: "Superficie del tetto dell'edificio",
                muah: 'Superficie utile (SU) per H',
                esa: 'Superficie libera esterna sistemata (SLES)',
                usa: 'Superficie utile (SU)',
                bv: "Volume dell'edificio (VE)",
                fgma: 'Superficie modello della parete esterna',
                x: 'Volumi estranei sulla proprietà (oggetti estranei)',
                muahv: 'Volume utile (VU) per H',
            },
            'import-control-form': {
                title: "Controllo dell'importazione",
                'description-1': 'Le lunghezze dei contorni sono corrette?',
                'description-2':
                    'Controllate se le dimensioni sono realistiche, regolate la scala se necessario o ricaricate il piano.',
                scale: 'Scalare',
                optimizations: 'Le ottimizzazioni sono state applicate',
                'new-upload': 'Ricaricare',
            },
            'terrain-control-form': {
                'terrain-title': 'Controllo del fondo',
                'terrain-description': 'Controllate la selezione del fondo se necessario.',
                'floor-title': '{{buildingName}}: {{floorName}} selezionare',
                'floor-description': 'Selezionate il contorno del piano sulla pianta o nella tabella.',
                name: 'Nome',
                area: 'Superficie',
                accept: 'Confermare',
                cancel: 'Cancellare',
                contour: 'Contorno',
            },
            'model-creation-form': {
                title: 'Costruire il modello',
                description: 'Aggiungete edifici e piani parziali al modello 3D.',
                name: 'Nome',
                height: 'Altezza (m)',
                actions: 'Azioni',
                submit: 'Blocca',
                fa: 'FA',
                'fa-long': 'Superficie di piano (SP)',
                muah: 'MUAH',
                'muah-long': 'Superficie utile (SU) per H',
                usa: 'USA',
                'usa-long': 'Superficie utile (SU)',
                roof: 'Tetto',
                area: 'Superficie (m²)',
                'h-required-tooltip': 'Si prega di selezionare i contorni e indicare la superficie utile (SU) per H.',
                'add-building': "Aggiungere l'edificio",
                floors: 'Pavimenti',
                'relative-elevation': 'Quota relativa del piano terra',
            },
        },
        'general-settings': {
            steps: {
                'mapping-selection': 'Impianti ad uso specifico edificio',
                BKP: 'Selezione dei progetti di riferimento $t(editor:regulation:BKP)',
                'eBKP-H2020(A)': 'Selezione dei progetti di riferimento $t(editor:regulation:eBKP-H)',
            },
            'none-available': {
                BKP: 'Nessun progetto di riferimento $t(editor:regulation:BKP) disponibile',
                'eBKP-H': 'Nessun progetto di riferimento $t(editor:regulation:eBKP-H) disponibile',
                'eBKP-H2020(A)': 'Nessun progetto di riferimento $t(editor:regulation:eBKP-H) disponibile',
            },
        },
        'regulation-mapping-selection': {
            confirm:
                'Sieti sicuri di voler fare questi cambiamenti? Tutti i dati di volumi e prezzi nella procedura guidata di conversione saranno persi.',
            'help-text': `
            Indicate qui sotto se il vostro progetto contiene valori rilevanti per il gruppo principale H "Impianti ad uso specifico edificio"`,
            option: {
                'tree-no-h': 'No',
                'tree-with-h': 'Si',
            },
            headers: {
                'code-description': 'Codice e designazione',
                total: 'CHF Totale',
                'h-value': 'CHF valore H',
                'h-percentage': '% valore H',
            },
        },
        'price-regeneration': {
            'all-chapters': 'Tutti',
            'data-invalid':
                'Ci sono degli input mancanti nella procedura guidata. Si prega di completarli prima di procedere al controllo di plausibilità.',
            'cost-value': {
                value: 'Importo CHF',
                'h-value': 'H CHF',
                'percentage-of': '% di',
            },
            'distribution-in-ebkph': 'Suddivisione secondo eCCC-E in %',
            headers: {
                status: 'Stato',
                code: 'Codice',
                regulation: 'Regolazione',
                lock: 'Bloccare',
            },
            'lock-all': 'Tutti',
            'progress-in-percentage': 'Avanzamento %',
            states: {
                checked: 'Completato',
                'to-be-checked': 'Da controllare',
                'can-be-checked': 'Può essere controllato',
                unknown: 'Ignoto',
            },
            steps: {
                'bkp-assignment': 'Suddivisione CCC',
            },
            total: 'Totale',
            filters: {
                checked: 'Completato',
                'to-be-checked': 'Da controllare',
                'can-be-checked': 'Può essere controllato',
                'all-nodes': 'Tutti i nodi',
                apply: 'Applicare',
                nodes: 'Nodi',
            },
            'bkp-override': {
                button: 'Sovrascrivere i prezzi $t(editor:regulation:BKP)',
                dialog: {
                    title: 'Sovrascrivere i prezzi $t(editor:regulation:BKP)',
                    description:
                        'Attivando questa funzione, i tuoi prezzi $t(editor:regulation:BKP) originali saranno sovrascritti dai prezzi definiti di seguito. Sei sicuro di voler sovrascrivere i tuoi prezzi $t(editor:regulation:BKP)?',
                    confirm: 'Sì, annulla i miei prezzi $t(editor:regulation:BKP)',
                    cancel: 'No, lascia i prezzi $t(editor:regulation:BKP) così come sono',
                },
            },
        },
        plausibility: {
            headers: {
                'short-name': 'Abbreviazione',
                'code-description': 'Codice e designazione',
                'reference-quantity': 'Quantità di riferimento',
                amount: 'Importo',
                'reference-projects-comparison': 'Confronto dei progetti di riferimento',
            },
            info: {
                'reference-projects': '\u2713 rispetto a {{param}} progetti di riferimento',
                'connection-3D-model': '\u2713 collegato al modello 3D',
                'connection-bcc-distribution': '\u2713 collegato al CCC',
                'data-missing':
                    'Ci sono degli input mancanti nella procedura guidata. Si prega di completarli prima di finire la procedura guidata.',
            },
            complete: 'Chiudere',
        },
        complete: {
            title: 'Fine della conversione?',
            description:
                'Siete sicuri di voler terminare la procedura guidata? Non sarai più in grado di modificare i valori.',
            error: 'Qualcosa è andato storto, per favore contattate',
        },
    },
    'box-plot': {
        range: '{{min}} - {{max}} {{unit}}',
        median: '{{median}} {{unit}}',
        'no-data': 'Nessun dato',
    },
    'regulation-statistic': {
        mean: 'Media',
    },
    'estimation-wizard': {
        'cost-estimation': {
            'save-pdf-export': 'Salvare & esportare PDF',
            'save-close': 'Salvare & Chiudere',
            cancel: 'Cancellare',
            'download-input-file': 'Esportazione di file IFC o DXF',
        },
    },
    'reference-projects': {
        'load-project-ids': 'Progetti di riferimento Applicare',
        'no-data': 'Non sono disponibili progetti di riferimento',
        'load-selected-project-ids': 'Applica selezionati',
        'load-short-list': 'Applica lista breve',
        'load-project-ids-error':
            'Si è verificato un errore durante il caricamento dei progetti di riferimento. Riprova o contatta CRB per risolvere il problema.',
    },
    categories: {
        'select-categories': 'Selezionare le categorie',
        all: 'Tutti',
        'total-costs': 'Costi totali',
    },
    tagEditor: {
        description: '',
        search: 'Ricerca',
        addTag: 'Aggiungi un tag',
        replace: {
            text: 'Il tag selezionato è già in uso. \nSelezionare il tag da sostituire a quello selezionato:',
            confirm: 'Sostituire',
            cancel: 'Annullamento',
        },
    },
};
