import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { override, valueFromPath } from '../../../../../../utils/project-utils';
import { SelectOption } from '../../../../../FormComponents';
import { Common } from './Common';
import { FieldOption } from './types';

const quelleLeftFields: Array<[string, FieldOption]> = [
    ['source.source', { input: 'text', type: 'text' }],
    ['source.catalogue', { input: 'text', type: 'text' }],
    ['source.internalNumber', { input: 'text', type: 'text' }],
];

const quelleRightFields: Array<[string, FieldOption]> = [
    ['source.dataSource', { input: 'text', type: 'text' }],
    ['source.publicationDate', { input: 'text', type: 'date' }],
    ['alternativeSource', { input: 'text', type: 'text' }],
];

interface SourcesProps {
    projects: ProjectBase[];
    project: Project;
    language: EditorLanguage;
    changeProject: (project: Project) => unknown;
}

export const Sources: React.FC<SourcesProps> = ({ projects, project, language, changeProject }) => {
    const { t } = useTranslation();
    const linkedProjectsFields = React.useMemo<Array<[string, FieldOption]>>(() => {
        return [
            [
                'linkedProjects',
                {
                    input: 'select',
                    type: 'multiple',
                    search: true,
                    options: projects.map<SelectOption>((proj) => ({
                        label: `${proj.id} - ${
                            proj.name[language.codes.editor] ||
                            proj.name[language.codes.documentation] ||
                            Object.values(proj.name)[0] ||
                            ''
                        }`,
                        value: proj.id,
                        hidden: project.id === proj.id,
                    })),
                },
            ],
        ];
    }, [project.id, language.codes, projects]);

    const handleChange = (path: string, value: string) => {
        changeProject(override(project, path, value));
    };

    return (
        <Row className="small-push-bottom">
            <Col xs={12}>
                <h3 className="mb-3">{t('editor:projects:creator:data-sheet:source')}</h3>
                <Row className="align-items-start">
                    <Col xs={12} lg={6}>
                        {quelleLeftFields.map(([field, options]) => (
                            <Common
                                name={t(`editor:project:parameters:${field.replace(/\./g, ':')}`)}
                                value={
                                    options.getValue
                                        ? options.getValue(project)
                                        : valueFromPath(project, field.split('.'))
                                }
                                changeValue={handleChange}
                                option={options}
                                language={language}
                                key={field}
                                passThrough={field}
                            />
                        ))}
                    </Col>
                    <Col xs={12} lg={6}>
                        {quelleRightFields.map(([field, options]) => (
                            <Common
                                name={t(`editor:project:parameters:${field.replace(/\./g, ':')}`)}
                                value={
                                    options.getValue
                                        ? options.getValue(project)
                                        : valueFromPath(project, field.split('.'))
                                }
                                changeValue={handleChange}
                                option={options}
                                language={language}
                                key={field}
                                passThrough={field}
                            />
                        ))}
                    </Col>
                </Row>

                <h3 className="mb-3 mt-5">{t('editor:projects:creator:data-sheet:linked-projects')}</h3>

                <Row className="align-items-start">
                    <Col xs={12} lg={12}>
                        {linkedProjectsFields.map(([field, options]) => (
                            <Common
                                name={t(`editor:project:parameters:${field.replace(/\./g, ':')}`)}
                                value={valueFromPath(project, field.split('.'))}
                                changeValue={handleChange}
                                option={options}
                                language={language}
                                key={field}
                                passThrough={field}
                                testId="linked-projects"
                            />
                        ))}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
