export default {
    name: "Editeur d'ouvrages",
    'object-calculator': "Calculateur d'objets",
    language: 'Langue de la documentation',
    'leave-editor': 'Vous êtes sûr de vouloir partir sans enregistrer les changements?',
    'open-ebkph-wizard': 'Conversion en $t(editor:regulation:eBKP-H)',
    'close-wizard': "Fermer l'assistant",
    'wizard-underway': {
        title: 'Attention!',
        message:
            "Attention: Vous avez déjà commencé à convertir ce projet dans l'assistant. Tout changement apporté aux valeurs référentielles résultera en la remise à zéro de l'assistant de converison.",
        'save-anyway': 'Enregistrer quand même',
        cancel: 'Annuler',
    },
    'arithmetically-incorrect-error-message':
        "La somme des montants $t(editor:regulation:BKP) des sous-catégories doit être égale au montant de la catégorie. Veuillez corriger vos entrées avant d'effectuer la conversion.",
    'input-file-import': {
        'incorrect-input-file-type': {
            dialog: {
                title: 'Mauvais type de fichier',
                description: 'Le type de fichier ne semble pas correct. Seuls les fichiers DXF ou IFC fonctionneront.',
                confirm: 'Ouvrez quand même',
                cancel: 'Choisissez un autre fichier',
            },
        },
    },
    regulation: {
        BKP: 'CFC',
        'eBKP-H': 'eCCC-Bât',
        'eBKP-T(A)': 'eCCC-GC(A)',
        'eBKP-T(B)': 'eCCC-GC(B)',
        'eBKP-H2020(A)': 'eCCC-Bât (A) 2020',
        'eBKP-H2020(B)': 'eCCC-Bât (B) 2020',
    },
    pages: {
        data: 'Fiche technique',
        measurements: 'Surfaces et volumes',
        buildings: 'Bâtiments',
        costs: 'Valeurs référentielles',
        energy: 'Energié',
        media: 'Plans et illustrations',
        wizard: 'Wizard',
    },
    project: {
        parameters: {
            id: 'ID',
            projectId: 'PrID',
            name: 'Nom',
            creator: 'Utilisateur-ID',
            owner: 'Entreprise',
            buildingClassCrb: {
                id: "Type d'ouvrage CRB OAG",
            },
            buildingClassPartners: {
                id: "Type d'ouvrage werk-material",
            },
            greaterRegion: { id: 'Grande région OFS' },
            type: 'Type de propriété',
            status: 'Statut',
            alternativeSource: 'Source alternative',
            linkedProjects: 'Projets liés',
            adminStatus: 'Publication Status',
            source: {
                source: 'Indication référence du projet',
                catalogue: 'Catalogue/répertoire/index',
                internalNumber: "N° interne/N° d'index",
                publicationDate: 'Date de publication',
                dataSource: 'La source de données',
            },
            descriptions: {
                generalDescription: 'Informations sur le projet',
                usageConcept: 'Programme d´unité',
                construction: 'Construction',
                buildingTechnology: 'Installations du bâtiment',
                materials: 'Matériaux',
                design: 'Conception',
                energy: 'Energié',
                userComfort: "Confort d'utilisation",
                organisation: 'Organisation du projet',
            },
            parameters: {
                constructionMethod: 'Type constructif',
                constructionMode: 'Construction',
                constructionMeasure: 'Type de construction',
                constructionOwner: "Maître d'ouvrage",
                projectPhase: 'Phase de projet',
            },
            participants: {
                name: 'Nom',
                role: 'Rôle',
                'delete-participant': 'Supprimer',
                'add-participant': 'Ajouter des intervenants',
            },
            projectTags: {
                title: 'Étiquettes',
                projectTagName: 'Nom du étiquette',
                deleteProjectTag: 'Supprimer',
                addProjectTag: 'Ajouter une étiquette',
                isNew: 'Créer une nouvelle étiquette',
                isPublic: 'Public',
                isPrivate: 'Privé',
                showTags: 'Afficher les tags',
                tagEditor: {
                    title: 'Tags du projet',
                },
                replaceDelete: {
                    title: 'Remplacer le tag',
                    description:
                        'Le tag est remplacé par le tag sélectionné, puis supprimé. \nSouhaitez-vous vraiment remplacer le tag?',
                    cancel: 'Annuler',
                    confirm: 'Remplacer',
                },
                confirmDelete: {
                    title: 'Supprimer le tag',
                    description:
                        'Le tag ne sera plus disponible après sa suppression. \nSouhaitez-vous vraiment supprimer le tag?',
                    cancel: 'Annuler',
                    confirm: 'Supprimer',
                },
            },
            floorUsages: {
                title: 'Utilisations',
                tagName: "Nom d'utilisation",
                regulation: 'Règlement',
                area: 'Zone',
                deleteFloorUsage: 'Supprimer',
                addFloorUsage: 'Ajouter un usage',
                isNew: 'Créer une nouvelle utilisation',
                isPublic: 'Public',
                isPrivate: 'Privé',
            },
            functionalUnits: {
                title: 'Unités fonctionnelles',
                amount: 'Quantité',
                functionalUnit: 'Unité fonctionelle',
                deleteFunctionalUnit: 'Supprimer',
                addFunctionalUnit: 'Ajouter des unités fonctionnelles',
                tags: {
                    showTags: 'Afficher les tags',
                    tagEditor: {
                        title: 'Unités fonctionnelles Tags',
                    },
                    replaceDelete: {
                        title: 'Remplacer le tag',
                        description:
                            'Le tag est remplacé par le tag sélectionné, puis supprimé. \nSouhaitez-vous vraiment remplacer le tag?',
                        cancel: 'Annuler',
                        confirm: 'Remplacer',
                    },
                    confirmDelete: {
                        title: 'Supprimer le tag',
                        description:
                            'Le tag ne sera plus disponible après sa suppression. \nSouhaitez-vous vraiment supprimer le tag?',
                        cancel: 'Annuler',
                        confirm: 'Supprimer',
                    },
                },
            },
            projectSpecificReadings: {
                statutoryPlanningFramework: 'Conditions générales du règlement des constructions',
                plotRatio: "Indice d'utilisation du sol",
                zoneAllocation: 'Appartenance à une zone',
                siteMasterplan: "Plan d'aménagement détaillé",
                plotRatioBonus: "Prime d'utilisation",
                mobility: 'Mobilité',
                parkingSpaces: 'Places de stationnement',
                cycleParkingSpaces: 'Râteliers à bicyclette',
                vehicleChargingStations: 'Bornes de recharge (électrique)',
            },
            projectAddress: {
                city: 'Lieu',
                streetName: 'Rue',
                streetNumber: 'No.',
                bfsNumber: 'Numéro OFS',
                latitude: 'Latitude',
                longitude: 'Longitude',
                plusCodes: 'Google Plus Codes',
                egidNumber: 'Id. fédéral de bâtiment (EGID)',
                additional: 'Supplément',
                location: {
                    municipality: 'Commune',
                    country: 'Pays',
                    countries: {
                        switzerland: 'Suisse',
                        liechtenstein: 'Liechtenstein',
                    },
                    canton: {
                        cantonName: 'Canton',
                    },
                    position: 'Situation',
                },
            },
            place: {
                address: {
                    country: 'Pays',
                    region: 'Grande région OFS',
                    canton: 'Canton',
                    municipality: 'Commune',
                    plz: 'NPA',
                },
                location: {
                    name: 'Situation',
                },
            },
            constructionTime: {
                competition: 'Décision du Concours',
                moveIn: 'Finalisation',
                planningStart: 'Début des études',
                constructionStart: 'Début des travaux',
                duration: 'Durée des travaux (Mois)',
            },
        },
        floor: {
            floorTypes: {
                UNDER_GROUND: 'R-',
                GROUND: 'RDC',
                ABOVE_GROUND: 'R+',
            },
            totalArea: 'SP in m²',
            area: 'SU in m²',
            utilisation: 'Utilisation',
            number: 'Nombre',
            'add-underground': 'Ajouter un étage en dessous',
            'add-overground': 'Ajouter un étage au-dessus',
            'delete-floor': 'Supprimer un étage',
            'delete-floor-area': 'Supprimer une utilisation',
            'add-floor-function': 'Ajouter une utilisation',
        },
    },
    projects: {
        details: {
            published: {
                true: 'Public',
                false: 'Privé',
            },
        },
        create: {
            title: 'Nouveau projet',
        },
        edit: {
            title: 'Modifier',
        },
        creator: {
            title: 'Nouveau projet',
            'data-sheet': {
                title: 'Fiche technique',
                source: 'Source',
                members: 'Participants',
                descriptions: 'Descriptions',
                'linked-projects': 'Projets liés',
            },
            'measurement-sheet': {
                title: 'Surfaces et volumes',
                label: 'Surface / Volume',
                sia: {
                    title: 'SIA les montants',
                },
            },
            'building-sheet': {
                title: 'Bâtiments',
                'discard-3d-model': 'Rejeter le modèle 3D',
                dialog: {
                    'delete-input-file': {
                        title: 'Supprimer le fichier',
                        description:
                            'Le fichier du projet est supprimé et ne peut plus être restauré. Voulez-vous continuer?',
                        confirm: '$t(common:yes)',
                        cancel: '$t(common:no)',
                    },
                },
            },
            'cost-sheet': {
                title: 'Valeurs référentielles',
                import: {
                    action: 'Importer',
                    dialog: {
                        error: {
                            title: "L'importation a échoué",
                            description:
                                "Un problème est survenu lors de l'importation du CSV. Veuillez réessayer ou contacter CRB pour vérifier le bon format.",
                            button: 'OK',
                        },
                        overrides: {
                            title: 'Remplacer les coûts existants',
                            description:
                                "Lors de l'importation, tous les coûts de la réglementation sont remplacés. Voulez-vous toujours poursuivre?",
                            cancel: 'Abandonner',
                            confirm: 'Continuer',
                        },
                        incorrect: {
                            title: 'Certains coûts seront corrigés',
                            description:
                                "Certains coûts ne sont pas corrects. Ils seront corrigés automatiquement lors de l'importation.",
                            cancel: 'Annuler',
                            confirm: 'OK',
                        },
                    },
                },
                columns: {
                    code: 'Code',
                    name: 'La désignation',
                    'short-name': 'Abréviation',
                    quantity: 'Quantité référentielle',
                    value: 'Montant',
                    checksum: 'Somme de contrôle',
                    description: 'La description',
                },
                rows: {
                    quantity: 'Quantité référentielle',
                    'value-in-chf': 'Montant in CHF',
                    description: 'La description',
                },
                'cost-index': {
                    level: 'Niveau de prix',
                },
                tabs: {
                    BKP: 'CFC',
                    'eBKP-H': 'eCCC-Bât',
                    'eBKP-T(A)': 'eCCC-GC(A)',
                    'eBKP-T(B)': 'eCCC-GC(B)',
                    'eBKP-H2020(A)': 'eCCC-Bât (A) 2020',
                    'eBKP-H2020(B)': 'eCCC-Bât (B) 2020',
                },
                'calculated-regulation': {
                    koa: "Coût de l'investissement",
                    kob: "Coût de l'ouvrage",
                    koe: 'Coût de réalisation',
                    kog: 'Coût du bâtiment',
                },
            },
            'energy-sheet': {
                title: 'Energie',
            },
            'media-sheet': {
                title: 'Plans et illustrations',
                table: {
                    nr: 'No.',
                    name: 'Nom',
                },
                'main-image': 'La vignette',
                upload: '+ Télécharger',
                filename: 'Nom de fichier',
                'delete-image': 'Image supprimer',
                'update-image-name': "Changer le nom de l'image",
                'limit-reached':
                    "Vous avez atteint le nombre maximum d'images pouvant être téléchargées pour un projet.",
                'file-too-big': "L'un des fichiers sélectionnés est trop volumineux.",
                'file-too-long': 'La dimension maximale autorisée pour une image est de 2400 px x 2400 px.',
                'click-here': 'Cliquez ici',
                or: 'ou',
                'drag-and-drop': 'glisser-déposer',
                'to-upload': 'pour télécharger des fichiers',
                'image-constraint': {
                    'accepted-types': 'Accepted image types are JPEG and PNG.',
                    'max-file-size': 'The maximum file size is 10 MB.',
                    'resize-note': 'Les images plus grandes que 2400x2400 seront redimensionnées.',
                },
            },
        },
        list: {
            title: 'Liste de projets',
            actions: {
                'document-new-project': 'Documenter un nouveau projet',
                'import-new-project': 'Importer un nouveau projet',
                'estimate-new-project': 'Calculateur eCCC-Bât',
                'copy-project': 'Copier',
                'edit-project': 'Modifier',
                'link-project': 'Relier',
                'delete-project': 'Supprimer',
                'change-project-status': 'Changer de statut',
                'lock-project-status': 'Publier',
                'unlock-project-status': 'Non publié',
                'export-projects': 'Exporter excel',
                'elektrosmog-export-projects': 'Exporter Elektrosmog',
                'transfer-projects': 'Transférer',
                'share-project': 'Partager',
            },
            'project-id': 'PrID',
            'project-name': 'Nom',
            'project-phase': 'Phase de projet',
            'project-links': 'Siehe noch',
            'project-postcode': 'NPA',
            'project-city': 'Commune',
            'project-canton': 'Canton',
            'project-year': 'Finalisation',
            'project-type': "Type d'ouvrage",
            'project-type-partners': "Type d'ouvrage werk-material",
            'project-status': 'Statut',
            'empty-list': "Aucun projet n'est disponible.",
            'no-access-to-projects': `Vous avez opté pour la version gratuite du calculateur d'objets et n'avez donc pas de projets. Passez à notre "Small License" pour avoir accès à tous vos projets préférés et profiter d'autres fonctionnalités ! 044 123 44 55 ou par mail à crb@crb.ch`,
            'project-public': {
                true: 'Public',
                false: 'Privé',
            },
            navigation: {
                page: 'Page',
                'of-page': 'de {{max}}',
            },
        },
        filter: {
            title: 'Filtrer les projets',
            apply: 'Appliquer le filtre',
            reset: 'Effacer le filtre',
            filters: {
                name: 'Nom',
                source: 'Source',
                category: 'Catégorie',
                canton: 'Canton',
                completionYear: "Année d'achèvement",
                regulations: 'Réglementation des prix',
            },
        },
    },
    actions: {
        'return-to-editor': 'Retour à la liste des projets',
        'everything-up-to-date': 'Toutes les données sont enregistrées',
        save: 'Enregistrer',
        finish: 'Terminer',
    },
    share: {
        title: 'Partager le projet',
        form: {
            title: 'Partager le projet "{{name}}"',
            userId: 'ID utilisateur',
            canView: 'Peut être visualisé dans la liste des projets',
            canEdit: 'Peut modifier le projet',
        },
    },
    'confirm-deletion': {
        confirm: 'Confirmer',
        cancel: 'Avorter',
        title: 'Supprimer le projet',
        description: 'Êtes-vous sûr de vouloir quitter cette page ? Tous les changements seront perdus.',
    },
    'bkp-to-ebkph-wizard': {
        steps: {
            'general-settings': 'Paramètres de base',
            volumes: 'Evaluation du volume',
            prices: 'Génération de prix',
            plausibility: 'Contrôle de plausibilité',
        },
        'volume-evaluation': {
            steps: {
                upload: 'Upload',
                control: 'Contrôle',
                terrain: 'Terrain',
                model: 'Modèle',
                results: 'Résultats',
            },
            dialog: {
                'delete-input-file': {
                    title: 'Supprimer le fichier',
                    description:
                        'Le fichier du wizard sera supprimé et ne pourra pas être restauré. Voulez-vous continuer?',
                    confirm: '$t(common:yes)',
                    cancel: '$t(common:no)',
                },
            },
            'building-plan-importer': {
                procedure: {
                    ifc: {
                        title: '3D - IFC: Créez un modèle 3D en utilisant les directives de modélisation (<1>LINK</1>)',
                        description:
                            "Vous pouvez enregistrer et télécharger ce modèle sous forme de fichier IFC: Dans ce cas, passez à l'étape 4. (Dernier point)",
                    },
                    dxf: {
                        title: '2D - DXF: Créez un modèle 3D à partir de vos plans',
                        description:
                            'Dans les étapes suivantes, construisez votre projet en 3D à partir des contours des bâtiments. Ce modèle va ensuite vous permettre le calcul automatique des surfaces, des volumes et autres chiffres clés.',
                    },
                },
                cad: {
                    ifc: { title: '3D - IFC:', description: "Passez à l'étape 4 (dernier point)" },
                    dxf: {
                        title: '2D - DXF: Ouvrez les plans dans votre programme de CAO',
                        description:
                            "Dessinez l'ensemble des contours des bâtiments et des parcelles à l'aide de la polyligne de votre programme de CAO. Suivez nos instructions en cas de doute.",
                    },
                },
                inputfile: {
                    ifc: { title: '3D - IFC:', description: "Passez à l'étape 4 (dernier point)" },
                    dxf: {
                        title: '2D - DXF: Exportez les contours en format DXF',
                        description:
                            'En dehors des contours, le DXF exporté ne doit contenir aucun autre élément tel que les murs, etc.',
                    },
                },
                upload: {
                    title: 'Téléchargez le fichier DXF ou IFC',
                    description:
                        'Faites glisser votre fichier DXF ou IFC sur le champ ci-dessus ou cliquez sur *Sélectionner DXF ou IFC*',
                },
                back: 'Retour',
                next: 'Continuer',
                'choose-input-file': 'Choisir DXF ou IFC',
                'file-too-large-upload-error':
                    'Le fichier téléchargé est trop volumineux. Veuillez utiliser un fichier de taille inférieure à {{param}}.',
                'reading-input-file-error':
                    "Le fichier n'a pas pu être lu. Assurez-vous que le fichier n'est pas endommagé et qu'il n'est pas utilisé par un autre programme et réessayez.",
                'general-upload-error':
                    "Une erreur s'est produite lors du téléchargement du fichier. Veuillez réessayer ou contacter",
                'ifc-error':
                    "Quelque chose s'est mal passé lors du téléchargement du fichier IFC. Veuillez changer d'onglet ou revenir en arrière, puis revenir sur cette page pour recommencer le téléchargement.",
            },
            results: {
                name: 'Nom',
                height: 'Hauteur (m)',
                area: 'Surface (m²)',
                'measurement-rule': 'Mode de métré',
                'short-name': 'Abréviation',
                value: 'Valeur',
                sa: 'Surface de terrain (ST)',
                fa: 'Surface de plancher (SP)',
                fga: 'Surface de façades et de murs contre terre',
                bra: 'Surface de toiture du bâtiment',
                muah: 'Surface utile (SU) pour H',
                esa: 'Surface des abords aménagés (SAA)',
                usa: 'Surface utile (SU)',
                bv: 'Volume bâti (VB)',
                fgma: 'Surface de façades et de murs contre terre selon maquette',
                x: 'Volumes étrangers sur le terrain (corps étrangers)',
                muahv: 'Volume utile (VU) pour H',
            },
            'import-control-form': {
                title: "Contrôler l'import",
                'description-1': 'Les indications de longueurs des contours sont-elles justes?',
                'description-2':
                    "Vérifiez si les mesures sont réalistes et adaptez l'échelle - si nécessaire -  ou bien chargez les plans une nouvelle fois.",
                scale: 'Mise à l’échelle',
                optimizations: 'Des optimisations ont été appliquées',
                'new-upload': 'Télécharger à nouveau',
            },
            'terrain-control-form': {
                'terrain-title': 'Contrôler le terrain',
                'terrain-description': 'Contrôlez la sélection du terrain si nécessaire.',
                'floor-title': 'Sélectionnez {{buildingName}}: {{floorName}}',
                'floor-description': "Sélectionnez le contour de l'étage sur le plan ou dans le tableau.",
                name: 'Nom',
                area: 'Surface',
                accept: 'Confirmer',
                cancel: 'Annuler',
                contour: 'Contour',
            },
            'model-creation-form': {
                title: 'Construire le modèle',
                description: 'Ajoutez des bâtiments partiels et des étages au modèle 3D.',
                name: 'Nom',
                height: 'Hauteur (m)',
                actions: 'Actions',
                submit: 'Terminer',
                fa: 'FA',
                'fa-long': 'Surface de plancher (SP)',
                muah: 'MUAH',
                'muah-long': 'Surface utile (SU) pour H',
                usa: 'USA',
                'usa-long': 'Superficie utile (SU)',
                roof: 'Toiture',
                area: 'Surface (m²)',
                'h-required-tooltip': 'Veuillez sélectionner les contours et indiquer la surface utile (SU) pour H.',
                'add-building': 'Ajouter un bâtiment',
                floors: 'Étages',
                'relative-elevation': 'Altitude relative du rez-de-chaussée',
            },
        },
        'general-settings': {
            steps: {
                'mapping-selection': 'Installations spécifiques',
                BKP: 'Sélection des projets de référence $t(editor:regulation:BKP)',
                'eBKP-H2020(A)': 'Sélection des projets de référence $t(editor:regulation:eBKP-H)',
            },
            'none-available': {
                BKP: 'Aucun projet de référence $t(editor:regulation:BKP) disponible',
                'eBKP-H': 'Aucun projet de référence $t(editor:regulation:eBKP-H) disponible',
                'eBKP-H2020(A)': 'Aucun projet de référence $t(editor:regulation:eBKP-H) disponible',
            },
        },
        'regulation-mapping-selection': {
            confirm:
                "Êtes-vous sûrs de vouloir apporter ces modifications? Vous allez perdre toutes les données de volume et de prix dans l'assistant de conversion.",
            'help-text': `Veuillez indiquer en dessous si votre projet contient des indications de prix pertinentes pour le groupe principal H "Installations spécifiques" de l'eCCC-Bât?`,
            option: {
                'tree-no-h': 'Non',
                'tree-with-h': 'Oui',
            },
            headers: {
                'code-description': 'Code et désignation',
                total: 'Total CHF',
                'h-value': 'Part H en CHF',
                'h-percentage': 'Part H en %',
            },
        },
        'price-regeneration': {
            'all-chapters': 'Tous',
            'data-invalid':
                "Il y a des entrées manquantes dans l'assistant. Veuillez les compléter avant de passer au contrôle de plausibilité.",
            'cost-value': {
                value: 'Montant CHF',
                'h-value': 'H CHF',
                'percentage-of': '% de',
            },
            'distribution-in-ebkph': 'Répartition vers eCCC-Bât en %',
            headers: {
                status: 'Statut',
                code: 'Code',
                regulation: 'Régulation',
                lock: 'Bloquer',
            },
            'lock-all': 'Tous',
            'progress-in-percentage': 'Avancement en %',
            states: {
                checked: 'Terminé',
                'to-be-checked': 'À vérifier',
                'can-be-checked': 'Peut être vérifié',
                unknown: 'Inconnu',
            },
            steps: {
                'bkp-assignment': 'Répartition CFC',
            },
            total: 'Total',
            filters: {
                checked: 'Terminé',
                'to-be-checked': 'À vérifier',
                'can-be-checked': 'Peut être vérifié',
                'all-nodes': 'Tous les noeuds',
                apply: 'Appliquer',
                nodes: 'Noeuds',
            },
            'bkp-override': {
                button: 'Remplacer les prix $t(editor:regulation:BKP)',
                dialog: {
                    title: 'Remplacer les prix $t(editor:regulation:BKP)',
                    description:
                        'En activant cette fonction, vos prix $t(editor:regulation:BKP) originaux seront remplacés par les prix définis ci-dessous. Êtes-vous sûr de vouloir remplacer vos prix $t(editor:regulation:BKP)?',
                    confirm: 'Oui, remplacez mes prix $t(editor:regulation:BKP)',
                    cancel: 'Non, laissez les prix $t(editor:regulation:BKP) tels quels',
                },
            },
        },
        plausibility: {
            headers: {
                'short-name': 'Abréviation',
                'code-description': 'Code et désignation',
                'reference-quantity': 'Quantité référentielle',
                amount: 'Montant',
                'reference-projects-comparison': 'Comparaison des projets de référence',
            },
            info: {
                'reference-projects': '\u2713 comparé avec {{param}} projet(s) de référence',
                'connection-3D-model': '\u2713 relié au modèle 3D',
                'connection-bcc-distribution': '\u2713 relié au CFC',
                'data-missing':
                    "Il y a des entrées manquantes dans l'assistant. Veuillez les compléter avant de terminer l'assistant.",
            },
            complete: 'Terminer',
        },
        complete: {
            title: 'Terminer la conversion?',
            description: "Êtes-vous sûr de vouloir terminer l'assistant ? Vous ne pourrez plus modifier les valeurs.",
            error: "Quelque chose s'est mal passé, veuillez contacter",
        },
    },
    'box-plot': {
        range: '{{min}} - {{max}} {{unit}}',
        median: '{{median}} {{unit}}',
        'no-data': 'Pas de information',
    },
    'regulation-statistic': {
        mean: 'Moyenne',
    },
    'estimation-wizard': {
        'cost-estimation': {
            'save-pdf-export': 'Enregistrer & Exporter PDF',
            'save-close': 'Enregistrer & Fermer',
            cancel: 'Annuler',
            'download-input-file': 'Exporter le fichier IFC ou DXF',
        },
    },
    'reference-projects': {
        'load-project-ids': 'Charger les projets de référence',
        'load-selected-project-ids': 'Appliquer la sélection',
        'load-short-list': 'Appliquer la liste restreinte',
        'no-data': "Il n'y a pas de projets de référence disponibles",
        'load-project-ids-error':
            "Une erreur s'est produite lors du chargement des projets de référence. Veuillez réessayer ou contacter CRB pour résoudre le problème.",
    },
    categories: {
        'select-categories': ' Sélectionner les catégories',
        all: 'Tous',
        'total-costs': 'Coûts totaux',
    },
    tagEditor: {
        description: '',
        search: 'Recherche',
        addTag: 'Ajouter un tag',
        replace: {
            text: 'La balise sélectionnée est déjà utilisée. \nVeuillez sélectionner la balise qui doit remplacer la balise sélectionnée:',
            cancel: 'Annuler',
            confirm: 'Remplacer',
        },
    },
};
