export default {
    name: 'Project Editor',
    'object-calculator': 'Object calculator',
    language: 'Documentation language',
    'leave-editor': 'Are you sure you want to leave without saving the changes?',
    'open-ebkph-wizard': 'Convert to $t(editor:regulation:eBKP-H)',
    'close-wizard': 'Close wizard',
    'wizard-underway': {
        title: 'Warning!',
        message:
            'Warning: You have a conversion wizard running. Any change on the $t(editor:regulation:BKP) prices will result in the loss of data in your conversion wizard.',
        'save-anyway': 'Save anyway',
        cancel: 'Cancel',
    },
    'arithmetically-incorrect-error-message':
        'The $t(editor:regulation:BKP) amounts of subcategories must sum up to the amount of the category. Please correct your inputs before performing the conversion.',
    'input-file-import': {
        'incorrect-input-file-type': {
            dialog: {
                title: 'Wrong File Type',
                description: 'The type of the file does not seem to be correct. Only DXF or IFC files will work.',
                confirm: 'Open the file anyway',
                cancel: 'Choose another file',
            },
        },
    },
    regulation: {
        BKP: 'BCC',
        'eBKP-H': 'eCC-BC',
        'eBKP-T(A)': 'eCC-CE(A)',
        'eBKP-T(B)': 'eCC-CE(B)',
        'eBKP-H2020(A)': 'eCC-BC (A) 2020',
        'eBKP-H2020(B)': 'eCC-BC (B) 2020',
    },
    pages: {
        data: 'Data sheet',
        measurements: 'Areas & volumes',
        buildings: 'Buildings',
        costs: 'Rates',
        energy: 'Energy',
        media: 'Pictures and drawings',
        wizard: 'Wizard',
    },
    project: {
        parameters: {
            id: 'ID',
            projectId: 'PrID',
            name: 'Name',
            creator: 'User-ID',
            owner: 'Company',
            buildingClassCrb: {
                id: 'Facility type CRB OAG',
            },
            buildingClassPartners: {
                id: 'Facility type werk-material',
            },
            greaterRegion: { id: 'FSO region' },
            type: 'Property type',
            status: 'Status',
            alternativeSource: 'Alternative source',
            linkedProjects: 'Linked projects',
            adminStatus: 'Publication Status',
            source: {
                source: 'Project reference data notes',
                catalogue: 'Catalog/Reference/Index',
                internalNumber: 'Internal no./Index no.',
                publicationDate: 'Publication date',
                dataSource: 'Data Source',
            },
            descriptions: {
                generalDescription: 'Project information',
                usageConcept: 'Space allocation plan',
                construction: 'KonstrukStructuretion',
                buildingTechnology: 'Building services',
                materials: 'Materials',
                design: 'Design',
                energy: 'Energy',
                userComfort: 'User comfort',
                organisation: 'Project organisation',
            },
            parameters: {
                constructionMethod: 'Construction type',
                constructionMode: 'Construction',
                constructionMeasure: 'Project type',
                constructionOwner: 'Client',
                projectPhase: 'Project phase',
            },
            participants: {
                name: 'Name',
                role: 'Role',
                'delete-participant': 'Delete',
                'add-participant': 'Add involved party',
            },
            projectTags: {
                title: 'Tags',
                projectTagName: 'Tag Name',
                deleteProjectTag: 'Delete',
                addProjectTag: 'Add Tag',
                isNew: 'Create new Tag',
                isPublic: 'Public',
                isPrivate: 'Privat',
                showTags: 'Show Tags',
                tagEditor: {
                    title: 'Project Tags',
                },
                replaceDelete: {
                    title: 'Replace Tag',
                    description:
                        'The tag is replaced with the selected tag and then deleted. \nDo you really want to replace the tag?',
                    cancel: 'Cancel',
                    confirm: 'Replace',
                },
                confirmDelete: {
                    title: 'Delete Tag',
                    description:
                        'The tag will no longer be available after deletion. \nDo you really want to delete the tag?',
                    cancel: 'Cancel',
                    confirm: 'Delete',
                },
            },
            floorUsages: {
                title: 'Usages',
                tagName: 'Usage',
                regulation: 'Regulation',
                area: 'Area',
                deleteFloorUsage: 'Delete',
                addFloorUsage: 'Add Usage',
                isNew: 'Create new Usage',
                isPublic: 'Public',
                isPrivate: 'Privat',
                tags: {
                    showTags: 'Show Tags',
                    tagEditor: {
                        title: 'Usage Tags',
                    },
                    replaceDelete: {
                        title: 'Replace Tag',
                        description:
                            'The tag is replaced with the selected tag and then deleted. \nDo you really want to replace the tag?',
                        cancel: 'Cancel',
                        confirm: 'Replace',
                    },
                    confirmDelete: {
                        title: 'Delete Tag',
                        description:
                            'The tag will no longer be available after deletion. \nDo you really want to delete the tag?',
                        cancel: 'Cancel',
                        confirm: 'Delete',
                    },
                },
            },
            functionalUnits: {
                title: 'Functional units',
                amount: 'Amount',
                functionalUnit: 'Functional unit',
                deleteFunctionalUnit: 'Delete',
                addFunctionalUnit: 'Add functional unit',
                tags: {
                    showTags: 'Show Tags',
                    tagEditor: {
                        title: 'Functional units tags',
                    },
                    replaceDelete: {
                        title: 'Replace Tag',
                        description:
                            'The tag is replaced with the selected tag and then deleted. \nDo you really want to replace the tag?',
                        cancel: 'Cancel',
                        confirm: 'Replace',
                    },
                    confirmDelete: {
                        title: 'Delete Tag',
                        description:
                            'The tag will no longer be available after deletion. \nDo you really want to delete the tag?',
                        cancel: 'Cancel',
                        confirm: 'Delete',
                    },
                },
            },
            projectSpecificReadings: {
                statutoryPlanningFramework: 'Statutory planning framework',
                plotRatio: 'Plot ratio',
                zoneAllocation: 'Zone allocation',
                siteMasterplan: 'Site masterplan',
                plotRatioBonus: 'Plot ratio bonus',
                mobility: 'Mobility',
                parkingSpaces: 'Parking spaces',
                cycleParkingSpaces: 'Cycle parking spaces',
                vehicleChargingStations: 'Vehicle charging stations',
            },
            projectAddress: {
                city: 'Place',
                streetName: 'Street',
                streetNumber: 'No.',
                bfsNumber: 'FSO number',
                latitude: 'Latitude',
                longitude: 'Longitude',
                plusCodes: 'Google Plus Codes',
                egidNumber: 'Federal building identifier (EGID)',
                additional: 'Supplement',
                location: {
                    municipality: 'Municipality',
                    country: 'Country',
                    countries: {
                        switzerland: 'Switzerland',
                        liechtenstein: 'Liechtenstein',
                    },
                    canton: {
                        cantonName: 'Canton',
                    },
                    position: 'Location',
                },
            },
            place: {
                address: {
                    country: 'Country',
                    region: 'FSO region',
                    canton: 'Canton',
                    municipality: 'Municipality',
                    plz: 'Post code',
                },
                location: {
                    name: 'Location',
                },
            },
            constructionTime: {
                competition: 'Competition decision',
                moveIn: 'Completion',
                planningStart: 'Start of design',
                constructionStart: 'Start on site',
                duration: 'Construction time (Months)',
            },
        },
        floor: {
            floorTypes: {
                UNDER_GROUND: 'B',
                GROUND: 'G',
                ABOVE_GROUND: '',
            },
            totalArea: 'FA in m²',
            area: 'UA in m²',
            utilisation: 'Use',
            number: 'Number',
            'add-underground': 'Add Floor below',
            'add-overground': 'Add storey above',
            'delete-floor': 'Delete storey',
            'delete-floor-area': 'Delete Use',
            'add-floor-function': 'Add Use',
        },
    },
    projects: {
        details: {
            published: {
                true: 'Public',
                false: 'Private',
            },
        },
        create: {
            title: 'New project',
        },
        edit: {
            title: 'Edit',
        },
        creator: {
            title: 'New project',
            'data-sheet': {
                title: 'Data sheet',
                source: 'Project reference data',
                members: 'Participants',
                descriptions: 'Descriptions',
                'linked-projects': 'Linked Projects',
            },
            'measurement-sheet': {
                title: 'Areas and Volumes',
                label: 'Surface / Volume',
                sia: {
                    title: 'SIA amounts',
                },
            },
            'building-sheet': {
                title: 'Buildings',
                'discard-3d-model': 'Discard 3D Model',
                dialog: {
                    'delete-input-file': {
                        title: 'Delete file',
                        description:
                            'The project file will be deleted and cannot be recovered. Do you want to continue?',
                        confirm: '$t(common:yes)',
                        cancel: '$t(common:no)',
                    },
                },
            },
            'cost-sheet': {
                title: 'Rates',
                import: {
                    action: 'Import',
                    dialog: {
                        error: {
                            title: 'Import failed',
                            description:
                                'Something went wrong with the import of the CSV. Please try again or contact CRB to verify the right format.',
                            cancel: 'OK',
                        },
                        overrides: {
                            title: 'Override existing costs',
                            description:
                                'When importing, all costs of regulation are replaced. Do you still want to proceed?',
                            cancel: 'Abort',
                            confirm: 'Continue',
                        },
                        incorrect: {
                            title: 'Some costs will be corrected',
                            description:
                                'Some costs are not correct. These will be corrected automatically during import.',
                            cancel: 'Cancel',
                            confirm: 'OK',
                        },
                    },
                },
                columns: {
                    code: 'Code',
                    name: 'Designation',
                    'short-name': 'Abbreviation',
                    quantity: 'Reference quantity',
                    value: 'Amount',
                    checksum: 'Checksum',
                    description: 'Description',
                },
                rows: {
                    quantity: 'Reference quantity',
                    'value-in-chf': 'Amount in CHF',
                    description: 'Description',
                },
                'cost-index': {
                    level: 'Price level',
                },
                tabs: {
                    BKP: 'BCC',
                    'eBKP-H': 'eCC-BC',
                    'eBKP-T(A)': 'eCC-CE(A)',
                    'eBKP-T(B)': 'eCC-CE(B)',
                    'eBKP-H2020(A)': 'eCC-BC (A) 2020',
                    'eBKP-H2020(B)': 'eCC-BC (B) 2020',
                },
                'calculated-regulation': {
                    koa: 'Investment costs',
                    kob: 'Construction facility costs',
                    koe: 'Production costs',
                    kog: 'Building costs',
                },
            },
            'energy-sheet': {
                title: 'Energy',
            },
            'media-sheet': {
                title: 'Pictures and drawings',
                table: {
                    nr: 'No.',
                    name: 'Name',
                },
                'main-image': 'Thumbnail',
                upload: '+ Upload',
                filename: 'Filename',
                'delete-image': 'Delete image',
                'update-image-name': 'Change image name',
                'limit-reached': 'You have reached the maximum number of images that can be uploaded for a project.',
                'file-too-big': 'One of the selected files is too big.',
                'file-too-long': 'The maximum permitted dimension for an image is 2400px x 2400px.',
                'click-here': 'Click here',
                or: 'or',
                'drag-and-drop': 'drag & drop',
                'to-upload': 'to upload images',
                'image-constraint': {
                    'accepted-types': 'Accepted image types are JPEG and PNG.',
                    'max-file-size': 'The maximum file size is 10 MB.',
                    'resize-note': 'Images larger than 2400x2400 will be resized.',
                },
            },
        },
        list: {
            title: 'Project list',
            actions: {
                'document-new-project': 'Document new project',
                'import-new-project': 'Import new project',
                'estimate-new-project': 'eBKP-H Calculator',
                'copy-project': 'Copy',
                'edit-project': 'Edit',
                'link-project': 'Link',
                'delete-project': 'Delete',
                'change-project-status': 'Change status',
                'lock-project-status': 'Publish',
                'unlock-project-status': 'Unpublish',
                'export-projects': 'Export excel',
                'elektrosmog-export-projects': 'Export Elektrosmog',
                'transfer-projects': 'Transfer',
                'share-project': 'Share',
            },
            'project-id': 'PrID',
            'project-name': 'Name',
            'project-phase': 'Project phase',
            'project-links': 'See also',
            'project-postcode': 'Post code',
            'project-city': 'Municipality',
            'project-canton': 'Canton',
            'project-year': 'Completion',
            'project-type': 'Facility type',
            'project-type-partners': 'Facility type werk-material',
            'project-status': 'Status',
            'empty-list': 'No projects available.',
            'no-access-to-projects': `You have chosen the free version of the object calculator and therefore have no projects available. Upgrade to our "Small License" to have access to all your valued projects and benefit from additional functionalities! 044 123 44 55 or by mail to crb@crb.ch`,
            'project-public': {
                true: 'Public',
                false: 'Private',
            },
            navigation: {
                page: 'Page',
                'of-page': 'of {{max}}',
            },
        },
        filter: {
            title: 'Filter project list',
            apply: 'Apply filter',
            reset: 'Clear filter',
            filters: {
                name: 'Name',
                source: 'Source',
                category: 'Category',
                canton: 'Canton',
                completionYear: 'Year of completion',
                regulations: 'Regulations',
            },
        },
    },
    actions: {
        'return-to-editor': 'Back to project list',
        'everything-up-to-date': 'All data is saved.',
        save: 'Save',
        finish: 'Finish',
    },
    share: {
        title: 'Share Project',
        form: {
            title: 'Share Project "{{name}}"',
            userId: 'User ID',
            canView: 'Can view in project list',
            canEdit: 'Can edit project',
        },
    },
    'confirm-deletion': {
        confirm: 'Confirm',
        cancel: 'Cancel',
        title: 'Delete project',
        description: 'Are you sure you want to leave the page? All changes will be lost.',
    },
    'bkp-to-ebkph-wizard': {
        steps: {
            'general-settings': 'Base settings',
            volumes: 'Volume evaluation',
            prices: 'Prices regeneration',
            plausibility: 'Plausibility',
        },
        'volume-evaluation': {
            steps: {
                upload: 'Upload',
                control: 'Control',
                terrain: 'Terrain',
                model: 'Model',
                results: 'Results',
            },
            dialog: {
                'delete-input-file': {
                    title: 'Delete file',
                    description: 'The wizard file will be deleted and cannot be restored. Do you want to continue?',
                    confirm: '$t(common:yes)',
                    cancel: '$t(common:no)',
                },
            },
            'building-plan-importer': {
                procedure: {
                    ifc: {
                        title: '3D - IFC: Create a 3D model using the modelling guidelines (<1>LINK</1>)',
                        description:
                            'You can save and upload this model as an IFC file. In this case, skip to step 4 (last bullet point).',
                    },
                    dxf: {
                        title: '2D - DXF: Create a 3D model from your floor plans',
                        description:
                            'In the next steps, build your project in 3D from the outlines of buildings.\nThe model is then used to automatically calculate areas, volumes and other key figures.',
                    },
                },
                cad: {
                    ifc: { title: '3D - IFC:', description: 'Skip to step 4 (Last bullet point).' },
                    dxf: {
                        title: '2D - DXF: Open the plans in your CAD',
                        description:
                            "Draw all building and lot outlines using your CAD program's polyline.\nFollow our directions if you are unsure.",
                    },
                },
                inputfile: {
                    ifc: { title: '3D - IFC:', description: 'Skip to step 4 (Last bullet point).' },
                    dxf: {
                        title: '2D - DXF: Export the outlines as DXF',
                        description:
                            'The exported DXF file must not contain any elements other than the outlines, such as walls, etc.',
                    },
                },
                upload: {
                    title: 'Upload the DXF or IFC file',
                    description: 'Drag your DXF or IFC file onto the field above, or click on "Select DXF or IFC".',
                },
                back: 'Back',
                next: 'Next',
                'choose-input-file': 'Select DXF or IFC',
                'file-too-large-upload-error':
                    'The uploaded file is too large. Please use a file size smaller than {{param}}.',
                'reading-input-file-error':
                    'The file could not be read. Make sure the file is not damaged and is not being used by another programme and try again.',
                'general-upload-error': 'There was an error while uploading the file. Please try again or contact',
                'ifc-error':
                    'Something went wrong while uploading the IFC file. Please switch tabs or navigate one step back and then come back to this page to perform the upload again.',
            },
            results: {
                name: 'Name',
                height: 'Height (m)',
                area: 'Area (m²)',
                'measurement-rule': 'Measurement rule',
                'short-name': 'Abbreviation',
                value: 'Value',
                sa: 'Site area (SA)',
                fa: 'Floor area (FA)',
                fga: 'External wall cladding, area',
                bra: 'Building roofing, area',
                muah: 'Usable area (UA) of section H',
                esa: 'Developed external area (ESA)',
                usa: 'Usable floor area (FA)',
                bv: 'Building volume (BV)',
                fgma: 'External wall cladding, modelled area',
                x: 'Foreign volumes on the property (foreign objects)',
                muahv: 'Usable volume for H',
            },
            'import-control-form': {
                title: 'Check Import',
                'description-1': 'Are the outline lengths correct?',
                'description-2':
                    'Check if the dimensions are realistic, and adjust the scaling if necessary, or re-upload the plan.',
                scale: 'Scaling',
                optimizations: 'Optimisations were applied',
                'new-upload': 'Re-upload',
            },
            'terrain-control-form': {
                'terrain-title': 'Check Terrain',
                'terrain-description': 'Control terrain selection if necessary',
                'floor-title': '{{buildingName}}: {{floorName}} choose',
                'floor-description': 'Select the outline of the floor on the plan or in the table.',
                name: 'Name',
                area: 'Area',
                accept: 'Confirm',
                cancel: 'Cancel',
                contour: 'Contour',
            },
            'model-creation-form': {
                title: 'Build model',
                description: 'Add partial buildings and floors to the 3D model.',
                name: 'Name',
                height: 'Height (m)',
                actions: 'Actions',
                submit: 'Submit',
                fa: 'FA',
                'fa-long': 'Floor area (FA)',
                muah: 'MUAH',
                'muah-long': 'Usable area (UA) of section H',
                usa: 'USA',
                'usa-long': 'Usable floor area (FA)',
                roof: 'Roof',
                area: 'Area (m²)',
                'h-required-tooltip': ' Please select the contours and fill in the usable area (UA) of section H.',
                'add-building': 'Add building',
                floors: 'Floors',
                'relative-elevation': 'Relative elevation of ground floor',
            },
        },
        'general-settings': {
            steps: {
                'mapping-selection': 'H-Quantities',
                BKP: 'Selection of Reference Projects $t(editor:regulation:BKP)',
                'eBKP-H2020(A)': 'Selection of Reference Projects $t(editor:regulation:eBKP-H)',
            },
            'none-available': {
                BKP: 'No $t(editor:regulation:BKP) reference projects available',
                'eBKP-H': 'No $t(editor:regulation:eBKP-H) reference projects available',
                'eBKP-H2020(A)': 'No $t(editor:regulation:eBKP-H) reference projects available',
            },
        },
        'regulation-mapping-selection': {
            confirm:
                'Are you sure you want to change your input? All the volume and price data in the conversion wizard will be lost.',
            'help-text': `
            Some of the $t(editor:regulation:BKP) prices entered for your project could possibly be partially
            mapped to category "H" of the $t(editor:regulation:eBKP-H) (use-specific building equipment).
            Please indicate bellow if your project contains H-relevant values.`,
            option: {
                'tree-no-h': 'The project does not contain H-relevant values',
                'tree-with-h': 'The project contains H-relevant values',
            },
            'project-without-h-values-option': 'My project has no ebkp-H H-relevant prices',
            'show-customization-option': 'Show and adjust suggestion',
            headers: {
                'code-description': 'Code and description',
                total: 'CHF Total',
                'h-value': 'CHF H-value',
                'h-percentage': '% H-value',
            },
        },
        'price-regeneration': {
            'all-chapters': 'All',
            'data-invalid':
                'There are missing inputs in the wizard. Please complete them before proceeding to the plausibility check.',
            'cost-value': {
                value: 'Amount CHF',
                'h-value': 'H CHF',
                'percentage-of': '% of',
            },
            'distribution-in-ebkph': 'Distribution in $t(editor:regulation:eBKP-H) in %',
            headers: {
                status: 'Status',
                code: 'Code',
                regulation: 'Regulation',
                lock: 'Lock',
            },
            'lock-all': 'All',
            'progress-in-percentage': 'Progress in %',
            states: {
                checked: 'Completed',
                'to-be-checked': 'To be checked',
                'can-be-checked': 'Can be checked',
                unknown: 'Unknown',
            },
            steps: {
                'bkp-assignment': '$t(editor:regulation:BKP) distribution',
            },
            total: 'total',
            filters: {
                checked: 'Checked',
                'to-be-checked': 'To be checked',
                'can-be-checked': 'Can be checked',
                'all-nodes': 'All nodes',
                apply: 'Apply',
                nodes: 'Nodes',
            },
            'bkp-override': {
                button: 'Override $t(editor:regulation:BKP) Prices',
                dialog: {
                    title: 'Override $t(editor:regulation:BKP) prices',
                    description:
                        'By enable this feature, your orignal $t(editor:regulation:BKP) Prices will be overwritten by the Prices defined bellow. Are you sure you want to override your $t(editor:regulation:BKP) Prices?',
                    confirm: 'Yes, override my $t(editor:regulation:BKP) prices',
                    cancel: 'No, leave $t(editor:regulation:BKP) Prices as is',
                },
            },
        },
        plausibility: {
            headers: {
                'short-name': 'Abbreviation',
                'code-description': 'Code and designation',
                'reference-quantity': 'Quantity',
                amount: 'Amount',
                'reference-projects-comparison': 'Reference projects comparison',
            },
            info: {
                'reference-projects': '\u2713 compared with {{param}} reference project(s)',
                'connection-3D-model': '\u2713 connected to 3D model',
                'connection-bcc-distribution': '\u2713 connected to BCC distribution',
                'data-missing':
                    'There are missing inputs in the wizard. Please complete them before finishing the wizard.',
            },
            complete: 'Finish',
        },
        complete: {
            title: 'Finish Conversion?',
            description: 'Are you sure you want to finish the wizard? You will no longer be able to edit the values.',
            error: 'Something went wrong, please contact',
        },
    },
    'box-plot': {
        range: '{{min}} - {{max}} {{unit}}',
        median: '{{median}} {{unit}}',
        'no-data': 'No data',
    },
    'regulation-statistic': {
        mean: 'Average',
    },
    'estimation-wizard': {
        'cost-estimation': {
            'save-pdf-export': 'Save & export PDF',
            'save-close': 'Save & Close',
            cancel: 'Cancel',
            'download-input-file': 'Export IFC or DXF file',
        },
        'reference-projects': {
            'load-project-ids': 'Load reference projects',
            'load-selected-project-ids': 'Apply selected',
            'load-short-list': 'Apply shortlist',
            'no-data': 'There are no reference projects available',
            'load-project-ids-error':
                'An error occurred while loading the reference projects. Please try again or contact CRB to resolve the issue.',
        },
        categories: {
            'select-categories': 'Select categories',
            all: 'All',
            'total-costs': 'Total costs',
        },
    },
    tagEditor: {
        description: '',
        search: 'Search',
        addTag: 'Add Tag',
        replace: {
            text: 'The selected tag is already in use. \nPlease select which tag should replace the selected tag:',
            confirm: 'Replace',
            cancel: 'Cancel',
        },
    },
};
