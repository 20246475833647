export function matchesRegulation(regulation: StaticRegulation, text: string, language: string): boolean {
    const regulationCode = regulation.code;
    const regulationName = (regulation.shortName[language] ?? regulation.shortName.de_DE).toLowerCase();
    const regulationDescription = (regulation.description[language] ?? regulation.description.de_DE).toLowerCase();

    text = text.toLowerCase();

    return (
        regulationCode.toLowerCase().includes(text.toLowerCase()) ||
        regulationName.toLowerCase().includes(text.toLowerCase()) ||
        regulationDescription.toLowerCase().includes(text.toLowerCase()) ||
        `${regulationCode} ${regulationName}`.toLowerCase().includes(text.toLowerCase()) ||
        `${regulationName} ${regulationDescription}`.toLowerCase().includes(text.toLowerCase()) ||
        `${regulationName} ${regulationName}`.toLowerCase().includes(text.toLowerCase()) ||
        toString(regulation, language).toLowerCase().includes(text.toLowerCase())
    );
}

export function toString(regulation: StaticRegulation | undefined, language: string): string {
    if (!regulation) return '';

    const { code } = regulation;
    const name = regulation.shortName[language];
    const description = regulation.description[language];

    return `${code} ${name} ${description}`;
}
