import { sortBy } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useProjectMediaDataChange, useProjectMediaDelete, useProjectMediaUpload } from '../../../../effects';
import { DraggableItem, DraggableList } from '../../../Draggable';
import { FileUploader } from '../../../FileUploader';
import { ViewProps } from '../types';
import { BaseView } from '../View';
import { DraggableMediaEntry } from './DraggableMediaEntry';
import { Media } from './Media';

const MAX_FILE_SIZE = 6 * 1024 * 1024;
const MAX_IMAGE_DIMENSION = 2400;
const MAX_FILE_COUNT = 30;

async function getImageDimensions(image: File) {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
            });
        };
        img.onerror = (e) => {
            reject(e);
        };
        const fr = new FileReader();
        fr.onload = () => {
            img.src = fr.result as string;
        };
        fr.onerror = (e) => {
            reject(e);
        };
        fr.readAsDataURL(image);
    });
}

export const MediaView: React.FC<ViewProps> = ({ project, changeProject, t }) => {
    const listRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
    const [fileIsTooBig, setFileIsTooBig] = useState(false);
    const [fileIsTooLong, setFileIsTooLong] = useState(false);
    const [uploading, setUploading] = useState(false);

    const { mutateAsync: uploadMedia } = useProjectMediaUpload(project.id);
    const { mutateAsync: deleteMedia } = useProjectMediaDelete(project.id);
    const { mutateAsync: changeMediaData } = useProjectMediaDataChange(project.id);

    const orderedMedia = useMemo(() => sortBy(project.media, ({ order }) => order), [project.media]);

    const mediaCount = orderedMedia.length;

    const selectedMedia = useMemo(() => {
        if (selectedIndex == null) return;

        return orderedMedia[selectedIndex];
    }, [orderedMedia, selectedIndex]);

    const selectedId = selectedMedia?.id;

    const handleMediaUpload = useCallback(
        async (fileOrFiles: File | File[]) => {
            const files = fileOrFiles instanceof Array ? fileOrFiles : [fileOrFiles];

            const firstImageToBeTooBig = files.find((f) => f.size >= MAX_FILE_SIZE);

            if (firstImageToBeTooBig) {
                setFileIsTooBig(true);

                return;
            }
            setFileIsTooBig(false);
            setUploading(true);

            let fileDims;
            try {
                fileDims = await Promise.all(files.map((f) => getImageDimensions(f)));
            } catch {
                setUploading(false);
                setFileIsTooLong(true);
                return;
            }

            if (fileDims.some((d) => d.width > MAX_IMAGE_DIMENSION || d.height > MAX_IMAGE_DIMENSION)) {
                setFileIsTooLong(true);
                setUploading(false);

                return;
            }
            setFileIsTooLong(false);

            const uploadable = MAX_FILE_COUNT - mediaCount;

            try {
                const newMedia: ProjectMedia[] = [];
                for (const file of files.slice(0, uploadable)) {
                    const res = await uploadMedia({
                        file,
                        mimeType: file.type,
                        name: file.name,
                    });
                    newMedia.push(res);
                }

                changeProject((p) => ({
                    ...p,
                    media: [...(p.media || []), ...newMedia].sort((m1, m2) => m2.order - m1.order),
                }));
            } finally {
                setUploading(false);
            }
        },
        [changeProject, uploadMedia, mediaCount]
    );

    const handleMediaSelect = useCallback(
        (index: ID) => setSelectedIndex((mediaId) => (mediaId !== index ? index : null)),
        []
    );

    /**
     *
     * Moves an image from it index to another position.
     *
     * !Important: The other image indexes will change too
     *
     * @param from Index of image
     * @param to New index of image
     */
    const handleMediaMove = useCallback(
        async (sourceIndex: number, targetIndex: number) => {
            if (sourceIndex === targetIndex) return;

            const source = orderedMedia[sourceIndex];

            try {
                await changeMediaData({
                    ...source,
                    order: targetIndex,
                });

                changeProject((p) => {
                    let newMedia = orderedMedia.filter((m) => m.order !== sourceIndex);

                    if (sourceIndex < targetIndex) {
                        newMedia = insertMediaAfter(newMedia, source, targetIndex);
                    } else {
                        newMedia = insertMediaBefore(newMedia, source, targetIndex);
                    }

                    return {
                        ...p,
                        media: updateMediaOrder(newMedia),
                    };
                });

                setSelectedIndex(targetIndex);
            } catch {
                // TODO: show an error
            }
        },
        [orderedMedia, changeMediaData, changeProject]
    );

    const handleMediaDelete = useCallback(async () => {
        if (!selectedMedia) return;

        await deleteMedia(selectedMedia.id);

        changeProject((p) => {
            const { order } = selectedMedia;
            if (order < 0) return p;

            const newMedia = p.media?.filter((m) => m.id !== selectedMedia.id).sort((a, b) => a.order - b.order);

            return {
                ...p,
                media: updateMediaOrder(newMedia ?? []),
            };
        });

        setSelectedIndex(null);
    }, [selectedMedia, deleteMedia, changeProject]);

    const handleMediaNameChange = useCallback(
        async (newName: string) => {
            changeProject((p) => ({
                ...p,
                media: p.media?.map((m) => {
                    if (m.id !== selectedId) return m;

                    return {
                        ...m,
                        name: newName,
                    };
                }),
            }));
        },
        [changeProject, selectedId]
    );

    const handleMediaNameSave = useCallback(async () => {
        if (!selectedMedia) return;

        await changeMediaData(selectedMedia);
    }, [selectedMedia, changeMediaData]);

    return (
        <BaseView name="media" title={t('editor:projects:creator:media-sheet:title')}>
            <Row>
                <Col xs={12} md={5} className="mvh-xs-50 mvh-md-auto overflow-auto">
                    {/* TODO: Show message instead of table when no media are uploaded yet */}
                    {/* {orderedMedia.length > 0 && ( */}
                    <>
                        <Row className="border-bottom py-3">
                            <Col xs={5} lg={4}>
                                {t('editor:projects:creator:media-sheet:table:nr')}
                            </Col>
                            <Col>{t('editor:projects:creator:media-sheet:table:name')}</Col>
                        </Row>

                        <Row>
                            <DraggableList className="media-list" ref={listRef}>
                                {orderedMedia.map((media, index) => (
                                    <DraggableItem
                                        index={index}
                                        onDragEnd={handleMediaMove}
                                        onClick={handleMediaSelect}
                                        key={media.id}
                                    >
                                        <DraggableMediaEntry
                                            media={media}
                                            selected={selectedIndex === index}
                                            isPreview={index === 0}
                                            t={t}
                                        />
                                    </DraggableItem>
                                ))}
                            </DraggableList>
                        </Row>
                    </>
                    {/* )} */}
                </Col>

                <Col className="mb-xs-3 mb-md-0" xs={12} md={1} />

                <FileUploader
                    multiple={true}
                    accept="image/*"
                    uploadFile={handleMediaUpload}
                    Component={Media as any}
                    Props={{
                        media: selectedMedia,
                        project,
                        onNameChange: handleMediaNameChange,
                        canUpload: mediaCount < MAX_FILE_COUNT,
                        uploadError: fileIsTooBig
                            ? t('editor:projects:creator:media-sheet:file-too-big')
                            : fileIsTooLong
                            ? t('editor:projects:creator:media-sheet:file-too-long')
                            : undefined,
                        onDelete: handleMediaDelete,
                        onSave: handleMediaNameSave,
                        uploading,
                    }}
                />
            </Row>
        </BaseView>
    );
};

function insertMediaBefore(media: ProjectMedia[], insertedMedia: ProjectMedia, targetIndex: number): ProjectMedia[] {
    return media.flatMap((m) => (m.order === targetIndex ? [insertedMedia, m] : [m]));
}

function insertMediaAfter(media: ProjectMedia[], insertedMedia: ProjectMedia, targetIndex: number): ProjectMedia[] {
    return media.flatMap((m) => (m.order === targetIndex ? [m, insertedMedia] : [m]));
}

function updateMediaOrder(media: ProjectMedia[]): ProjectMedia[] {
    return media.map((m, i) => ({
        ...m,
        order: i,
    }));
}
